import {
  CaremergeConfiguration, ICaremergeTenantAssocInfo,
  V1SyncAddFacilityOptions, V1SyncCheckAccessReport, V1SyncedAccountModel,
  V1SyncJobModel, V1SyncJobRequest, V1SyncJobWithLogs,
  V1SyncMatchingReport,
} from 'types/V1SyncTypes';
import { IValidationResult } from 'types/IValidationResult';
import API, { AxiosRequestOptions } from './API';

export const getTenantsWithInfo = async (
  options?: AxiosRequestOptions,
): Promise<Record<number, ICaremergeTenantAssocInfo | null>> => {
  const response = await API.get<Record<number, ICaremergeTenantAssocInfo | null>>(
    '/api/v2/v1sync/tenants/configs',
    options,
  );
  return response.data;
};

export const getTenantConfig = async (
  tenantId: number, options?: AxiosRequestOptions,
): Promise<CaremergeConfiguration> => {
  const response = await API.get<CaremergeConfiguration>(
    `/api/v2/v1sync/tenants/${tenantId}/config`,
    options,
  );
  return response.data;
};

export const editTenantConfig = async (
  tenantId: number,
  config: CaremergeConfiguration,
  ignoreValidationWarnings: boolean,
  options?: AxiosRequestOptions,
): Promise<IValidationResult> => {
  const response = await API.put<IValidationResult>(
    `/api/v2/v1sync/tenants/${tenantId}/config?ignoreWarnings=${ignoreValidationWarnings}`,
    config,
    options,
  );
  return response.data;
};

export const addTenantConfig = async (
  tenantId: number,
  config: CaremergeConfiguration,
  ignoreValidationWarnings: boolean,
  options?: AxiosRequestOptions,
): Promise<IValidationResult> => {
  const response = await API.post<IValidationResult>(
    `/api/v2/v1sync/tenants/${tenantId}/config?ignoreWarnings=${ignoreValidationWarnings}`,
    config,
    options,
  );
  return response.data;
};

export const updateMappingEnablements = async (
  tenantId: number,
  accountsEnabledUpdate: Record<number, boolean>,
  options?: AxiosRequestOptions,
): Promise<IValidationResult> => {
  const response = await API.put<IValidationResult>(
    `/api/v2/v1sync/tenants/${tenantId}/config/mappings/enabled`,
    accountsEnabledUpdate,
    options,
  );
  return response.data;
};

export const getAllJobsForTenant = async (
  tenantId: number,
  options?: AxiosRequestOptions,
): Promise<V1SyncJobModel[]> => {
  const response = await API.get<V1SyncJobModel[]>(
    `/api/v2/v1sync/tenants/${tenantId}/jobs`,
    options,
  );
  return response.data;
};

export const getJobWithLogs = async (
  jobId: number,
  levelFilter?: string,
  options?: AxiosRequestOptions,
): Promise<V1SyncJobWithLogs> => {
  const response = await API.get<V1SyncJobWithLogs>(
    `/api/v2/v1sync/jobs/${jobId}`,
    { params: { levelFilter }, ...options },
  );
  return response.data;
};

export const startTenantSyncJob = async (
  tenantId: number,
  jobRequest: V1SyncJobRequest,
  options?: AxiosRequestOptions,
): Promise<{ JobId: number }> => {
  const response = await API.post<{ JobId: number }>(
    `/api/v2/v1sync/tenants/${tenantId}/jobs`,
    jobRequest,
    options,
  );
  return response.data;
};

export const startAddFacilityJob = async (
  args: V1SyncAddFacilityOptions,
  options?: AxiosRequestOptions,
): Promise<{ JobId: number }> => {
  const response = await API.post<{ JobId: number }>(
    `/api/v2/v1sync/tenants/${args.TenantId}/jobs/addFacility`,
    args,
    options,
  );
  return response.data;
};

export const getAllV1SyncedAccounts = async (
  includeUnsynced: boolean,
  options?: AxiosRequestOptions,
): Promise<V1SyncedAccountModel[]> => {
  const response = await API.get<V1SyncedAccountModel[]>(
    '/api/v2/v1sync/accounts',
    { params: { includeUnsynced }, ...options },
  );
  return response.data;
};

export const checkSyncUserFacilityAccess = async (
  tenantId: number,
  facilityId: number,
  accountId: number,
  options?: AxiosRequestOptions,
): Promise<V1SyncCheckAccessReport> => {
  const response = await API.get<V1SyncCheckAccessReport>(
    `/api/v2/v1sync/${tenantId}/${facilityId}/hasAccess`,
    { params: { accountId }, ...options },
  );
  return response.data;
};

export const getV1SyncMatchingReport = async (
  tenantId: number,
  facilityId: number,
  accountId: number,
  options?: AxiosRequestOptions,
): Promise<V1SyncMatchingReport> => {
  const response = await API.get<V1SyncMatchingReport>(
    `/api/v2/v1sync/${tenantId}/${facilityId}/fuzzyMatch/${accountId}`,
    options,
  );
  return response.data;
};
