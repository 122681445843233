import React, { ReactElement, useCallback, useMemo } from 'react';
import { Helmet } from 'react-helmet';
import { Redirect, useHistory, useParams } from 'react-router-dom';
import { useHasRoles } from 'hooks/useHasRoles';
import { useJobsForTenant } from 'hooks/V1SyncHooks';
import { startTenantSyncJob } from 'api/V1SyncAPI';
import { Card, Spinner } from 'react-bootstrap';
import { V1SyncJobRequest } from 'types/V1SyncTypes';
import { ReactComponent as Plus } from 'styles/images/plus.svg';
import TenantJobsTable from './TenantJobsTable';
import V1SyncJobLauncher from './V1SyncJobLauncher';

const TenantJobsPage = (): ReactElement => {
  const history = useHistory();
  const [isAdmin, isSuper] = useHasRoles(['Admin', 'Super']);
  const hasAccessPermission = isAdmin || isSuper;

  const { tenantId } = useParams<{ tenantId?: string }>();
  const tenantIdNumber = Number(tenantId);

  const { data: jobs, mutate, isValidating } = useJobsForTenant(tenantIdNumber);

  const activeJob = useMemo(() => jobs?.find((x) => x.Status === 'InProgress'), [jobs]);

  const launchJob = useCallback(async (jobInfo: V1SyncJobRequest) => {
    const r = await startTenantSyncJob(tenantIdNumber, jobInfo);
    mutate();
    return r.JobId;
  }, [mutate, tenantIdNumber])

  if (!hasAccessPermission) {
    return <Redirect to="/" />;
  }

  const title = `V1 Sync Jobs for Tenant ${tenantId ?? ''}`;
  return (
    <div className="broadcast-forms Templates">
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <div className="d-flex align-items-center">
        <h3>
          {title}
        </h3>

        <button
          type="button"
          disabled={isValidating}
          className="btn btn-link ml-2"
          onClick={() => mutate()}
        >
          click to refresh
        </button>
        <Spinner
          hidden={!isValidating}
          animation="border"
          variant="primary"
          size="sm"
          className="ml-2"
        />
      </div>

      <p className="mt-4">
        Launch new synchronization jobs, and view past results. Only one job may be running
        for a tenant at a time.
      </p>
      <button
        role="link"
        type="button"
        onClick={() => history.push(`/v1sync/${tenantId}/add`)}
        className="btn btn-lg btn-warning "
      >
        <Plus
          width="24"
          height="24"
          fill="currentColor"
          style={{ marginRight: '10px' }}
        />
        Add New Facilty
      </button>
      <Card className="mb-4 mt-4">
        <Card.Header as="h5">Job Launcher</Card.Header>
        <Card.Body>
          <V1SyncJobLauncher tenantId={tenantIdNumber} onLaunchJob={launchJob} activeJob={activeJob} />
        </Card.Body>
      </Card>
      { !!tenantIdNumber && <TenantJobsTable tenantId={tenantIdNumber} /> }
    </div>
  );
};

export default TenantJobsPage;
