export enum ValidationLevel {
  Info = 'Info',
  Warning = 'Warning',
  Error = 'Error',
}

export interface IValidationResultEntry {
  Message: string
  Level: ValidationLevel
}

export interface IValidationResult {
  Entries: IValidationResultEntry[]
  HasWarnings: boolean
  HasErrors: boolean
  LastMessage?: string
}
