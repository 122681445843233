import React, { ReactElement, useState } from 'react';
import clsx from 'clsx';
import { Label } from 'components/Label';
import uuidv4 from 'uuid/v4';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Input from './Input';

const questionCircle = <FontAwesomeIcon icon="question-circle" size="1x" />

export interface IMultiListInputProps {
  id: string
  name: string
  label?: string
  value?: string[]
  required?: boolean
  className?: string
  placeholder?: string
  maxInputs?: number
  maxLength?: number
  errors?: string
  onChange?: (values: string[]) => void
  disabled?: boolean
  customTooltipIcon?: JSX.Element
  tooltip?: string
}

function MultiListInput({
  id, name, label, value = [], required, className = '', placeholder = '', errors, onChange, disabled,
  maxInputs, maxLength, customTooltipIcon, tooltip,
}: IMultiListInputProps): ReactElement {
  const [inputValue, setInputValue] = useState('');

  const removeItem = (item: string) => {
    onChange?.(value.filter((v) => v !== item));
  };

  const tooltipIcon = customTooltipIcon ?? questionCircle;

  return (
    <div className={clsx('form-group', className)}>
      {label && (
        <label htmlFor={id}>
          {label}
        &nbsp;
          {tooltip && (
            <OverlayTrigger
              placement="top"
              overlay={(
                <Tooltip id={`tooltip-${uuidv4()}`}>
                  {tooltip}
                </Tooltip>
              )}
            >
              {tooltipIcon}
            </OverlayTrigger>
          )}
          {!tooltip && customTooltipIcon}
        </label>
      )}
      {(!maxInputs || value.length < maxInputs) && (
        <div className="d-flex align-items-center">
          <Input
            className="mb-4 mr-4 flex-grow-1"
            type="text"
            placeholder={placeholder ?? ''}
            value={inputValue}
            maxLength={maxLength}
            onKeyDown={(evt) => {
              if (evt.key === 'Enter') {
                evt.preventDefault();
                onChange?.([...value, inputValue]);
                setInputValue('');
              }
            }}
            onChange={(e) => {
              setInputValue(e.target.value);
            }}
          />
          <button
            type="button"
            className="btn btn-primary square mb-4 "
            onClick={() => {
              onChange?.([...value, inputValue]);
              setInputValue('');
            }}
          >
            Add
          </button>
        </div>
      )}
      {errors && <span className="errors">{errors}</span>}
      {value && value.map((item) => (
        <Label
          className="mr-2 mb-2"
          name={item}
          key={item}
          showDelete
          onDelete={() => {
            removeItem(item);
          }}
        />
      ))}
    </div>
  );
}

export default MultiListInput;
