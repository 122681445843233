import React, {
  useEffect, useState, ReactElement,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Redirect, useHistory, useLocation,
} from 'react-router-dom';
import Dropzone from 'react-dropzone';
import FileDragContext from 'lib/FileDragContext';
import {
  fetchUserInfo, generateNewDomainRedirectUrl, getCurrentAccount, getUserState, logout, redirectToIconDomain,
} from 'reducers/UserInfo';
import { fetchUserDetail } from 'reducers/UserDetail';
import { isIE } from 'react-device-detect';
import { enableES5 } from 'immer';
import { TokenExpiryTimer } from 'components/TokenExpiryTimer';
import { IdleTimeOutModal } from 'components/SessionTimeoutPrompt';
import { useIdleTimer } from 'react-idle-timer';
import { IUserInfoState } from 'reducers/IUserInfoState';
import { Header } from 'components/Header';
import { tokenIdleTimeout } from 'lib/helpers/ExpiredToken';
import { useHasFeatures } from 'hooks/useHasFeatures';
import LoadingOverlay from 'components/LoadingOverlay';
import { useIsLoginValid } from 'hooks/useIsLoginValid';
import { useInitUserflow } from 'hooks/useInitUserflow';
import { RootState } from '../types/rootState';
import AppRoutes from '../routes/AppRoutes';

declare global {
  interface Window {
    Beacon: (x: string, y: any) => void
    analytics: any
  }
}

function DropzoneContext(
  { children }: { children: ReactElement | ReactElement[] },
): ReactElement {
  const [isFileDraggedOnPage, setIsFileDraggedOnPage] = useState(false);
  const dispatch = useDispatch();

  const userInfo = useSelector<RootState, IUserInfoState>((state: RootState) => state.UserInfo);
  const currentAccount = useSelector(getCurrentAccount);
  const currentAccountName = currentAccount?.Name;
  const currentAccountId = currentAccount?.Id;
  const currentAccountType = currentAccount?.CustomerType;
  const isLoggedIn = userInfo?.loggedIn;
  const userEmail = userInfo?.email;
  const userFirstName = userInfo?.FirstName || '';
  const userLastName = userInfo?.LastName || '';
  const userFullName = `${userFirstName} ${userLastName}`;
  const { loginUserId } = userInfo;
  useEffect(() => {
    if (isLoggedIn && currentAccountName) {
      let email = userEmail;

      if (email.indexOf('@') === -1) {
        email = '';
      }
      window.Beacon('identify', {
        name: userFullName.trim() === '' ? email : userFullName,
        email,
        Account: currentAccountName,
        Interface: 'voicefriend-react',
      });
      // db ids should *never* change.

      if (loginUserId) {
        window.analytics.identify(`${process.env.REACT_APP_ENVIRONMENT || ''}${loginUserId}`, {
          name: userFullName,
          firstName: userFirstName,
          lastName: userLastName,
          email,
          groupId: currentAccountId,
          company: {
            name: currentAccountName,
            id: currentAccountId,
          },
        });

        window.analytics.group(currentAccountId, {
          industry: currentAccountType,
          name: currentAccountName,
        });
      }
    }
  }, [currentAccountId,
    currentAccountName,
    currentAccountType,
    isLoggedIn,
    loginUserId,
    userEmail,
    userFirstName,
    userFullName,
    userLastName]);

  useEffect(() => {
    dispatch(fetchUserDetail());
  }, [dispatch]);

  const contextValue = { isFileDraggedOnPage, setIsFileDraggedOnPage }
  return (
    <Dropzone
      onDragEnter={() => { setIsFileDraggedOnPage(true) }}
      onDragLeave={() => { setIsFileDraggedOnPage(false) }}
      onDragOver={() => { setIsFileDraggedOnPage(true) }}
      onDrop={async () => {
        setIsFileDraggedOnPage(false);
      }}
    >
      {({ getRootProps }) => (
        <FileDragContext.Provider value={contextValue}>
          {/* eslint-disable-next-line react/jsx-props-no-spreading */}
          <div {...getRootProps({ className: 'VoiceFriend-app' })}>
            {children}
          </div>
        </FileDragContext.Provider>
      )}
    </Dropzone>
  )
}

function App(): ReactElement {
  const history = useHistory();
  const location = useLocation();
  const { jwt, token } = useSelector((state: RootState) => state.UserInfo);
  const [showPrompt, setShowPrompt] = useState<boolean>(false);
  const [timeUntilLogout, setTimeUntilLogout] = useState<number>(60);
  const isLoginValid = useIsLoginValid();
  const userState = useSelector(getUserState);
  const currentAccountId = userState.userInfo?.AccountDetail?.AccountID;
  const dispatch = useDispatch();

  useInitUserflow();

  const { hasFeature: iconRedirectFlag, isLoading: isLoadingRedirectCheck } = useHasFeatures('icon-redirect');
  const isOldDomain = !!generateNewDomainRedirectUrl();
  const showFetchingAccountInfo = !currentAccountId
    || isLoadingRedirectCheck
    || (isOldDomain && iconRedirectFlag as boolean);

  useIdleTimer({
    timeout: tokenIdleTimeout(jwt),
    onIdle: () => {
      setShowPrompt(true)
    },
  })

  if (isIE) {
    enableES5();// the profile list will get error if do not enable ES5. after enable ES5, i can save profiles
  }
  useEffect(() => {
    window.Beacon('event', {
      type: 'page-viewed',
      url: document.location.href,
      title: document.title,
    });
    window.Beacon('suggest', null);
    if (location && location.pathname === '/') {
      window.Beacon('navigate', '/')
    }
    window.analytics.page();
  }, [history, location]);

  useEffect(() => {
    if (isLoginValid) {
      dispatch(fetchUserInfo());
    }
  }, [dispatch, isLoginValid]);

  if (!isLoginValid) {
    return (
      <Redirect to={`/login?redirectTo=${document.location.pathname}${encodeURIComponent(document.location.search)}`} />
    );
  }

  if (iconRedirectFlag) {
    redirectToIconDomain(token);
  }

  // check if legacy route, if so delay loading of app until account info has been fully fetched
  // this is necessary so that on login?redirect=/legacy/xxx we have time to set the session cookie
  // hacky solution: cleanup when possible (old domain or legacy is deprecated)
  if (showFetchingAccountInfo && window.location.pathname.startsWith('/legacy')) {
    return (
      <LoadingOverlay isVisible loadingText="Fetching account information..." />
    );
  }

  return (
    <DropzoneContext>
      <LoadingOverlay loadingText="Fetching account information..." isVisible={showFetchingAccountInfo} />
      <Header />
      <AppRoutes />
      <TokenExpiryTimer />
      <IdleTimeOutModal
        showModal={showPrompt}
        handleClose={() => {
          setShowPrompt(false)
          setTimeout(() => { setTimeUntilLogout(60); }, 1000);
        }}
        handleLogout={() => {
          dispatch(logout({ redirectTo: window.location.pathname, reason: 'timeout' }));
        }}
        timeUntilLogout={timeUntilLogout}
        setTimeUntilLogout={setTimeUntilLogout}
      />
    </DropzoneContext>
  );
}

export default App;
