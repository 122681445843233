import { checkSyncUserFacilityAccess, getV1SyncMatchingReport, startAddFacilityJob } from 'api/V1SyncAPI';
import Input from 'components/Inputs/Input';
import SearchableDropdown from 'components/Inputs/SearchableDropdown';
import useTryAsync from 'hooks/useTryAsync';
import { useAllV1SyncedAccounts } from 'hooks/V1SyncHooks';
import React, { useMemo, useState, useCallback } from 'react';
import {
  Button, Form, Spinner,
} from 'react-bootstrap';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import Checkbox from 'components/Inputs/Checkbox';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import FacilityAccessReportCard from './FacilityAccessReportCard';
import FacilityMatchingReportCard from './FacilityMatchingReport';

const questionCircle = <FontAwesomeIcon icon="question-circle" size="1x" />

const V1SyncAddFacilityPage: React.FC = () => {
  const history = useHistory();
  const { tenantId } = useParams<{ tenantId?: string }>();
  const tenantIdNumber = Number(tenantId);

  const [selectedAccountId, setSelectedAccountId] = useState<number>();
  const [facilityId, setFacilityId] = useState<string>();

  // Additional configuration state for the add facility job
  const [shouldMergeData, setShouldMergeData] = useState<boolean>(false);
  const [mergeThreshold, setMergeThreshold] = useState<number>(0.8);
  const [deleteUnsyncedFamily, setDeleteUnsyncedFamily] = useState<boolean>(false);
  const [deleteUnsyncedStaff, setDeleteUnsyncedStaff] = useState<boolean>(false);
  const [deleteUnsyncedResidents, setDeleteUnsyncedResidents] = useState<boolean>(false);
  const [shouldPushResidents, setShouldPushResidents] = useState<boolean>(false);
  const [shouldPushStaff, setShouldPushStaff] = useState<boolean>(false);
  const [shouldPushFamily, setShouldPushFamily] = useState<boolean>(false);

  const checkAccess = useTryAsync(
    async () => checkSyncUserFacilityAccess(tenantIdNumber, Number(facilityId), selectedAccountId),
    {
      onSuccess: (result) => toast.success(result),
      onError: (error: any) => toast.error(error?.response?.data?.Message ?? 'Something went wrong'),
    },
  );

  const matchingReport = useTryAsync(
    () => getV1SyncMatchingReport(tenantIdNumber, Number(facilityId), selectedAccountId),
  );

  const addFacilityJob = useTryAsync(
    useCallback(() => startAddFacilityJob({
      TenantId: tenantIdNumber,
      FacilityId: Number(facilityId),
      AccountId: selectedAccountId,
      ShouldMergeData: shouldMergeData,
      MergeThreshold: mergeThreshold,
      DeleteUnsyncedFamily: deleteUnsyncedFamily,
      DeleteUnsyncedStaff: deleteUnsyncedStaff,
      DeleteUnsyncedResidents: deleteUnsyncedResidents,
      ShouldPushResidents: shouldPushResidents,
      ShouldPushStaff: shouldPushStaff,
      ShouldPushFamily: shouldPushFamily,
    }),
    [
      tenantIdNumber,
      facilityId,
      selectedAccountId,
      shouldMergeData,
      mergeThreshold,
      deleteUnsyncedFamily,
      deleteUnsyncedStaff,
      deleteUnsyncedResidents,
      shouldPushResidents,
      shouldPushStaff,
      shouldPushFamily,
    ],
    ),
    {
      onSuccess: (result) => {
        toast.success(`Successfully started add facility job ${result.JobId}`);
        history.push(`/v1sync/${tenantId}/jobs/${result.JobId}`);
      },
      onError: (error: any) => toast.error(error?.response?.data?.Message ?? 'Something went wrong'),
    },
  );

  const { data: accounts } = useAllV1SyncedAccounts(true);
  const unsycedAccountOptions = useMemo(() => {
    const unsyncedAccounts = accounts?.filter((a) => !a.IsSynced) ?? [];
    unsyncedAccounts.sort((a, b) => a.AccountName.localeCompare(b.AccountName));
    return unsyncedAccounts.map((a) => ({
      value: a.AccountId,
      label: `${a.AccountName} -- ${a.AccountId}`,
    }));
  }, [accounts]);

  return (
    <div className="mt-4">
      <Form>
        <Form.Group controlId="accountSelect" className="mb-3">
          <Form.Label>Account</Form.Label>
          <SearchableDropdown
            options={unsycedAccountOptions}
            value={unsycedAccountOptions.find((a) => a.value === selectedAccountId)}
            onChange={(opt) => setSelectedAccountId(Number(opt?.value))}
            placeholder="Select an account"
          />
        </Form.Group>

        <Form.Group controlId="facilityId" className="mb-3">
          <Form.Label>Facility ID</Form.Label>
          <Input
            type="text"
            placeholder="Enter facility id"
            value={facilityId}
            onChange={(e) => {
              setFacilityId(e.target.value);
              checkAccess.reset();
            }}
          />
          <Button
            variant="primary"
            disabled={!facilityId || !selectedAccountId || checkAccess.loading || checkAccess.status === 'success'}
            onClick={() => checkAccess.run()}
            className="mt-2"
          >
            Check Facility
          </Button>
          <Spinner
            as="span"
            hidden={!checkAccess.loading}
            animation="border"
            variant="primary"
            size="sm"
            className="ml-2"
          />
        </Form.Group>

        {checkAccess.result && (
          <div className="mb-3">
            <FacilityAccessReportCard report={checkAccess.result} />
          </div>
        )}

        {matchingReport.result && <FacilityMatchingReportCard report={matchingReport.result} />}

        {checkAccess.result && (
          <div className="d-flex align-items-center mb-3">
            <Button variant="primary" onClick={() => matchingReport.run()}>
              Check Matching
            </Button>
            <Spinner
              as="span"
              hidden={!matchingReport.loading}
              animation="border"
              variant="primary"
              size="sm"
              className="ml-2"
            />
            {matchingReport.loading && (
              <h5 className="text-info ms-3 mb-0 ml-2">
                Computing Matching Report... this may take awhile
              </h5>
            )}
          </div>
        )}

        {checkAccess.result && (
          <>

            <h4 className="mt-4">Configure Add Facility Job</h4>
            <Checkbox
              className="mt-4"
              label="Should Merge Data"
              name="Should Merge Data"
              onChange={(e) => setShouldMergeData(e.target.checked)}
              checked={shouldMergeData}
              customIconTooltip={`If selected, first will run an algorithm to match profiles
                of the same user type by name, email and phone numbers. The threshold value determines
                how agressively matching is made, on a scale of 0.0 (Very Agressive) to 3.0 (Perfect Match). 
                It is recommended to first check and review the matching before starting this job
                to help determine what threshold value you want to use. Typcially a value between
                0.8 and 2.5 is recommended.`}
              customIcon={questionCircle}
            />

            {shouldMergeData && (
              <Form.Group controlId="mergeThreshold" className="mb-3 mt-2">
                <Form.Label>Merge Threshold (0.0 - 3.0)</Form.Label>
                <Form.Control
                  type="number"
                  step="0.1"
                  min="0"
                  max="3"
                  value={mergeThreshold}
                  onChange={(e) => setMergeThreshold(Number(e.target.value))}
                />
              </Form.Group>
            )}

            <Checkbox
              className="mt-4"
              label="Should Push Staff"
              name="Should Push Staff"
              onChange={(e) => setShouldPushStaff(e.target.checked)}
              checked={shouldPushStaff}
              customIconTooltip={`Check if you want comms staff profiles to be 
                pushed to caremerge before enabling the sync`}
              customIcon={questionCircle}
            />

            <Checkbox
              label="Delete Unsynced Staff"
              name="Delete Unsynced Staff"
              checked={deleteUnsyncedStaff}
              onChange={(e) => setDeleteUnsyncedStaff(e.target.checked)}
              customIconTooltip={`Check if you want to delete all unsynced staff profiles before enabling
                the sync. This step occurs after merging and pushing comms staff
                (if those options were selected). Note: If unsynced staff profiles are present, enabling 
                the v1 sync will fail.`}
              customIcon={questionCircle}
            />

            <Checkbox
              className="mt-4"
              label="Should Push Residents"
              name="Should Push Residents"
              checked={shouldPushResidents}
              onChange={(e) => setShouldPushResidents(e.target.checked)}
              customIconTooltip={`Check if you want comms resident profiles to be 
                pushed to caremerge before enabling the sync`}
              customIcon={questionCircle}
            />

            <Checkbox
              label="Delete Unsynced Residents"
              name="Delete Unsynced Residents"
              checked={deleteUnsyncedResidents}
              onChange={(e) => setDeleteUnsyncedResidents(e.target.checked)}
              customIconTooltip={`Check if you want to delete all unsynced resident profiles before enabling
                the sync. This step occurs after merging and pushing comms resident
                (if those options were selected). Note: If unsynced resident profiles are present,
                enabling the v1 sync will fail.`}
              customIcon={questionCircle}
            />

            <Checkbox
              className="mt-4"
              label="Should Push Family"
              name="Should Push Family"
              checked={shouldPushFamily}
              onChange={(e) => setShouldPushFamily(e.target.checked)}
              customIconTooltip={`Check if you want comms family profiles to be 
                pushed to caremerge before enabling the sync. Note: only family profiles that have a relationship
                to a resident specified will be pushed`}
              customIcon={questionCircle}
            />

            <Checkbox
              label="Delete Unsynced Family"
              name="Delete Unsynced Family"
              checked={deleteUnsyncedFamily}
              onChange={(e) => setDeleteUnsyncedFamily(e.target.checked)}
              customIconTooltip={`Check if you want to delete all unsynced family profiles before enabling
                the sync. This step occurs after merging and pushing comms family
                (if those options were selected)`}
              customIcon={questionCircle}
            />

          </>
        )}

        {checkAccess.result && (
          <div className="mb-3 mt-4">
            <Button variant="primary" onClick={() => addFacilityJob.run()}>
              Sync
            </Button>
          </div>
        )}
      </Form>
    </div>
  );
};

export default V1SyncAddFacilityPage;
