import React, {
  ReactElement, useEffect, useState,
} from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { useParams, useHistory } from 'react-router-dom';
import { useTenantConfig } from 'hooks/V1SyncHooks';
import {
  Button, Card, Form, Spinner,
} from 'react-bootstrap';
import {
  AdditionalFieldSyncOptions, CaremergeConfiguration, CaremergeEmailSyncOption, CaremergeEnvConfigOption,
  CaremergeInactivePeriodSyncConfig,
} from 'types/V1SyncTypes';
import { addTenantConfig, editTenantConfig } from 'api/V1SyncAPI';
import { toast } from 'react-toastify';
import { Checkbox, Input, Select } from 'components/FormControls';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useToOptionsList } from 'hooks/useToOptionsList';
import SelectInput, { IOptions } from 'components/Inputs/Select';

import MultiSelect from 'components/FormControls/MultiSelect';
import MultiListInput from 'components/Inputs/MultiListInput';
import ControlledCheckbox from 'components/Inputs/Checkbox';

import { IValidationResult } from 'types/IValidationResult';
import ValidationResult from 'components/Common/ValidationResult';
import V1SyncWebhookSettings from 'components/V1Sync/V1SyncWebhookSettings';
import { useIsModifed } from 'hooks/useIsModified';

const questionCircle = <FontAwesomeIcon icon="question-circle" size="1x" />

const optionsIncludeValue = (options: IOptions[], value: any) => (options.findIndex(
  (opt) => opt.value === value) >= 0 ? null : 'Must select a valid option');

const isPostiveInteger = (value: any, errMsg = 'Must be a positive integer'): string => {
  try {
    const intVal = parseInt(value, 10);
    if (intVal <= 0) return errMsg;
  } catch (e) {
    return errMsg;
  }
  return null;
}

const lifeDateMappingOptions: IOptions[] = [
  {
    label: 'Date of Birth',
    value: 'dob',
  },
  {
    label: 'Date of Hire',
    value: 'doh',
  },
]

// TODO: check validation results in webhooks component, account mapping validation, clean up edit page

const V1SyncTenantConfigEditPage = (): ReactElement => {
  const { tenantId } = useParams<{ tenantId?: string }>();
  const history = useHistory();
  const isEditing = tenantId !== 'new';
  const { data: existingConfig, isLoading, mutate } = useTenantConfig(isEditing ? Number(tenantId) : 'new');
  const [loading, setLoading] = useState<boolean>(false);

  const [validation, setValidation] = useState<IValidationResult | null>(null);
  const [ignoreValidationWarnings, setIgnoreValidationWarnings] = useState<boolean>(false);

  const environmentStr = process.env.REACT_APP_ENVIRONMENT
    ? CaremergeEnvConfigOption.Staging
    : CaremergeEnvConfigOption.Production;

  const defaultFormValues: CaremergeConfiguration = {
    ImportType: 'Caremerge',
    Env: environmentStr,
    TenantID: 0,
    CaremergeSyncUserId: 0,
    SyncStatus: 'SyncOn',
    CaremergePhoneNumberFormat: '(###) ###-####',
    StaffEmailSyncOption: CaremergeEmailSyncOption.UserEmail,
    FamilyEmailSyncOption: CaremergeEmailSyncOption.ContactEmail,
    ResidentEmailSyncOption: CaremergeEmailSyncOption.ContactEmail,
    IconUsersOnly: false,
    AdditionalFieldSync: [],
    Facilities: [],
  };

  const {
    handleSubmit,
    reset,
    control,
    formState: { errors },
  } = useForm<CaremergeConfiguration>({
    defaultValues: defaultFormValues,
  });

  const [inactivePeriodConfigs, setInactivePeriodConfigs] = useState<CaremergeInactivePeriodSyncConfig[]>([]);

  const isModified = useIsModifed(
    existingConfig,
    { ...existingConfig, ...control.getValues(), InactivePeriodConfigs: inactivePeriodConfigs });

  // rebuild the page when changes are made, this way
  // validation error messages will be properly updated
  useWatch({ control });

  useEffect(() => {
    if (existingConfig) {
      reset(existingConfig);
      setInactivePeriodConfigs(existingConfig.InactivePeriodConfigs ?? []);
    }
  }, [existingConfig, reset])

  const syncStatusOptions = useToOptionsList(['SyncOn', 'SyncOff']);
  const emailSyncOptions = useToOptionsList(Object.values(CaremergeEmailSyncOption));
  const numberSyncOptions = useToOptionsList(['(###) ###-####', '###-###-####', '### ### ####']);
  const additionalFieldSyncOptions = useToOptionsList(Object.values(AdditionalFieldSyncOptions));
  const inactivePeriodOptions = additionalFieldSyncOptions
    .filter((x) => inactivePeriodConfigs.findIndex((c) => c.FieldName === x.value) === -1);

  const onSubmit = async (data: CaremergeConfiguration) => {
    setValidation(null);
    const payload = { ...existingConfig, ...data };
    payload.InactivePeriodConfigs = inactivePeriodConfigs;
    setLoading(true);
    try {
      let validationResult: IValidationResult;
      if (isEditing) {
        validationResult = await editTenantConfig(Number(tenantId), payload, ignoreValidationWarnings);
      } else {
        validationResult = await addTenantConfig(payload.TenantID, payload, ignoreValidationWarnings);
      }

      if (validationResult.HasErrors) {
        setValidation(validationResult);
        toast.error('Failed to save config, errors are present');
        return;
      }

      if (validationResult.HasWarnings && !ignoreValidationWarnings) {
        setValidation(validationResult);
        toast.warn('Failed to save config, warnings are present');
        return;
      }

      toast.success(`Updated configuration for tenant ${payload.TenantID}`);
      mutate();
      if (!isEditing) {
        history.goBack()
      }
    } catch (error) {
      toast.error('Failed to save tenant config', error);
    } finally {
      setLoading(false);
    }
  };

  if (isEditing && isLoading) {
    return <Spinner animation="border" role="status" />;
  }

  return (
    <div className="container">
      <h2>
        {isEditing ? 'Edit' : 'Add'}
        {' '}
        Tenant Configuration
      </h2>
      {isEditing && (
        <p className="mt-4 mb-4">
          When editing a configuration for an active V1 sync care must be taken.
          An invalid configuration can break the V1 sync procress. Additionally, depending on the changes made
          you will most likely need to trigger a background job to re-sync the tenant.
        </p>
      )}
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Card className="mt-4">
          <Card.Header as="h4">Tenant Settings</Card.Header>
          <Card.Body style={{ paddingRight: 40 }}>

            <Input
              type="text"
              id="ImportType"
              name="ImportType"
              label="Import Type"
              required
              control={control}
              disabled
              rules={{
                required: 'Import Type is required',
              }}
              errors={errors.ImportType?.message}
            />

            <Input
              type="text"
              id="Env"
              name="Env"
              label="Environment"
              placeholder="Select environment"
              control={control}
              disabled
              errors={errors.Env?.message}
              required
            />

            <Input
              type="text"
              id="TenantID"
              name="TenantID"
              label="Tenant ID"
              required
              disabled={isEditing}
              control={control}
              rules={{
                required: 'Tenant ID is required',
                validate: (val: any) => isPostiveInteger(val),
              }}
              errors={errors.TenantID?.message}
            />

            <Input
              label="Caremerge Sync User ID"
              id="CaremergeSyncUserId"
              name="CaremergeSyncUserId"
              control={control}
              type="text"
              required
              errors={errors.CaremergeSyncUserId?.message}
              customIconTooltip={`This is the ID of the caremerge ops+<tenantId> user. This user
                needs to be created and granted access to any of the facilities on Caremerge that are
                mapped with the CS-Caremerge Employee permission assigned.`}
              customIcon={questionCircle}
              rules={{
                required: 'Caremerge Sync User ID is required',
                validate: (val: any) => isPostiveInteger(val),
              }}
            />

            <Select
              id="SyncStatus"
              name="SyncStatus"
              label="Sync Status"
              placeholder="Select sync status"
              tooltip={`This should always be enabled, when sync status is off 
                behaviour currently isn't well defined/tested`}
              required
              control={control}
              options={syncStatusOptions}
              errors={errors.SyncStatus?.message}
              rules={{
                validate: (val: any) => optionsIncludeValue(syncStatusOptions, val),
              }}
            />

            <Select
              label="Phone Number Format"
              name="CaremergePhoneNumberFormat"
              id="CaremergePhoneNumberFormat"
              placeholder="Select phone number format"
              tooltip="Indicates how phone numbers should be formatted when syncing to Engagement"
              control={control}
              options={numberSyncOptions}
              required
              errors={errors.CaremergePhoneNumberFormat?.message}
              rules={{
                validate: (val: any) => optionsIncludeValue(numberSyncOptions, val),
              }}
            />

            <h5 className="mt-4">User Linking Options</h5>
            <Checkbox
              customIcon={questionCircle}
              customIconTooltip="This should be enabled only when comms accounts have no existing users"
              label="Auto Link Icon Users?"
              id="IconUsersOnly"
              name="IconUsersOnly"
              control={control}
            />

            <h5 className="mt-4">Email Syncing Options</h5>
            <Select
              label="Staff Email Sync Option"
              name="StaffEmailSyncOption"
              id="StaffEmailSyncOption"
              placeholder="Select email sync option"
              tooltip={`Controls what email field on engagement is synced.
            For Staff, typically UserEmail is used unless a Comms Staff integration exists.`}
              control={control}
              options={emailSyncOptions}
              required
              errors={errors.StaffEmailSyncOption?.message}
              rules={{
                validate: (val: any) => optionsIncludeValue(emailSyncOptions, val),
              }}
            />

            <Select
              label="Resident Email Sync Option"
              name="ResidentEmailSyncOption"
              id="ResidentEmailSyncOption"
              placeholder="Select email sync option"
              tooltip="Controls what email field on engagement is synced, Contact Email is recommended for Residents."
              control={control}
              options={emailSyncOptions}
              required
              errors={errors.ResidentEmailSyncOption?.message}
              rules={{
                validate: (val: any) => optionsIncludeValue(emailSyncOptions, val),
              }}
            />
            <Select
              label="Family Email Sync Option"
              name="FamilyEmailSyncOption"
              id="FamilyEmailSyncOption"
              placeholder="Select email sync option"
              tooltip="Controls what email field on engagement is synced, Contact Email is recommended for Family."
              control={control}
              options={emailSyncOptions}
              required
              errors={errors.FamilyEmailSyncOption?.message}
              rules={{
                validate: (val: any) => optionsIncludeValue(emailSyncOptions, val),
              }}
            />

            <h5 className="mt-4">Field Mapping Options</h5>

            <Select
              label="Life Date 1 Mapping"
              name="LifeDate1Mapping"
              id="LifeDate1Mapping"
              placeholder="None"
              control={control}
              options={lifeDateMappingOptions}
              errors={errors.LifeDate1Mapping?.message}
            />

            <Select
              label="Life Date 2 Mapping"
              name="LifeDate2Mapping"
              id="LifeDate2Mapping"
              placeholder="None"
              control={control}
              options={lifeDateMappingOptions}
              errors={errors.LifeDate2Mapping?.message}
            />

            <MultiSelect
              label="Additional Field Sync"
              id="AdditionalFieldSync"
              name="AdditionalFieldSync"
              placeholder="Select additional field syncing options"
              tooltip={`Add any additional field sync options requested by the customer 
                -- by default we dont include any.`}
              control={control}
              options={additionalFieldSyncOptions}
              errors={errors.AdditionalFieldSync?.join()}
            />

            <h5 className="mt-4">Inactive Period Sync</h5>
            <SelectInput
              id="select-inactive-period-option"
              name="select-inactive-period-option"
              placeholder="Select option to add"
              options={inactivePeriodOptions}
              onChange={(v) => setInactivePeriodConfigs((prev) => [...prev, { FieldName: v, InactiveFieldValues: [] }])}
            />

            {inactivePeriodConfigs.map((x, index) => {
              const name = x.FieldName;
              return (
                <div className="ml-4 mt-4" key={name}>
                  <div className="d-flex align-items-center">
                    <button
                      className="mr-2 mb-4 border-0 bg-transparent text-danger fw-bold"
                      type="button"
                      onClick={() => setInactivePeriodConfigs((prev) => prev.filter((_, i) => i !== index),
                      )}
                    >
                      <FontAwesomeIcon
                        icon="times"
                        size="sm"
                      />
                    </button>
                    <h6 className="mb-4">{name}</h6>
                  </div>
                  <MultiListInput
                    id={`multi-list-${name}`}
                    placeholder={`Add inactive value for ${name}`}
                    name={`multi-list-${name}`}
                    value={x.InactiveFieldValues}
                    label={name}
                    tooltip={`When this field for a profile equals any of the values specified,
                  the profile will be set to inactive.`}
                    maxLength={50}
                    onChange={(v) => setInactivePeriodConfigs((prev) => {
                      const updated = [...prev];
                      updated[index].InactiveFieldValues = v;
                      return updated;
                    })}
                  />
                </div>
              );
            })}

            <div style={{ marginTop: 40 }} />
            <div className="d-flex align-items-center">
              <Button variant="primary" type="submit" className="ml-2" disabled={loading || !isModified}>
                {isEditing ? 'Update' : 'Add'}
              </Button>
              <Spinner
                as="span"
                hidden={!loading}
                animation="border"
                variant="primary"
                size="sm"
                className="ml-2"
              />
              <ControlledCheckbox
                className="ml-4"
                name="Ignore Validation Warnings"
                label="Ignore Validation Warnings"
                checked={ignoreValidationWarnings}
                onChange={(e) => setIgnoreValidationWarnings(e.target.checked)}
                customIcon={questionCircle}
                customIconTooltip="When checked, will save the config even if warnings are present."
              />
            </div>
            {validation && <ValidationResult className="mt-4" result={validation} />}
          </Card.Body>
        </Card>
      </Form>
      {isEditing
       && (
         <Card className="mt-4">
           <Card.Header as="h4">Account-Facility Mappings</Card.Header>
           <Card.Body style={{ paddingRight: 40 }}>
             <V1SyncWebhookSettings tenantId={Number(tenantId)} />
           </Card.Body>
         </Card>
       )}
    </div>
  );
};

export default V1SyncTenantConfigEditPage;
