import ValidationResult from 'components/Common/ValidationResult';
import React from 'react';
import { Card, Accordion, ListGroup } from 'react-bootstrap';
import { V1SyncCheckAccessReport, V1SyncVfProfileSummary, CmUserProfileSummary } from 'types/V1SyncTypes';

interface AccessReportCardProps {
  report: V1SyncCheckAccessReport
}

const AccessReportCard: React.FC<AccessReportCardProps> = ({ report }) => {
  // Helper function to render V1SyncVfProfileSummary items
  const renderVfProfileSummary = (item: V1SyncVfProfileSummary) => (
    <ListGroup.Item key={item.Id}>
      {`${item.FirstName} ${item.LastName} | ${item.EmailAddress}`}
    </ListGroup.Item>
  );

  // Helper function to render CmUserProfileSummary items
  const renderCmProfileSummary = (item: CmUserProfileSummary) => (
    <ListGroup.Item key={item.UserId}>
      {`${item.FirstName} ${item.LastName} | ${item.Email}`}
    </ListGroup.Item>
  );

  return (
    <Card className="mb-3">
      <Card.Header>Access Report</Card.Header>
      <Card.Body>
        <Card.Title>{report.Message}</Card.Title>
        {report.ValidationResult && <ValidationResult className="mt-4" result={report.ValidationResult} />}
        <Accordion defaultActiveKey="-1">
          <Card>
            <Card.Header>
              <Accordion.Toggle as="div" eventKey="1">
                {`VfStaff (${report.VfStaff.length} unsynced)`}
              </Accordion.Toggle>
            </Card.Header>
            <Accordion.Collapse eventKey="1">
              <Card.Body>
                {report.VfStaff.length > 0 ? (
                  <ListGroup variant="flush">
                    {report.VfStaff.map(renderVfProfileSummary)}
                  </ListGroup>
                ) : (
                  <p>No items</p>
                )}
              </Card.Body>
            </Accordion.Collapse>
          </Card>

          <Card>
            <Card.Header>
              <Accordion.Toggle as="div" eventKey="4">
                {`CmStaff (${report.CmStaff.length} total)`}
              </Accordion.Toggle>
            </Card.Header>
            <Accordion.Collapse eventKey="4">
              <Card.Body>
                {report.CmStaff.length > 0 ? (
                  <ListGroup variant="flush">
                    {report.CmStaff.map(renderCmProfileSummary)}
                  </ListGroup>
                ) : (
                  <p>No items</p>
                )}
              </Card.Body>
            </Accordion.Collapse>
          </Card>

          <Card>
            <Card.Header>
              <Accordion.Toggle as="div" eventKey="2">
                {`VfResidents (${report.VfResidents.length} unsynced)`}
              </Accordion.Toggle>
            </Card.Header>
            <Accordion.Collapse eventKey="2">
              <Card.Body>
                {report.VfResidents.length > 0 ? (
                  <ListGroup variant="flush">
                    {report.VfResidents.map(renderVfProfileSummary)}
                  </ListGroup>
                ) : (
                  <p>No items</p>
                )}
              </Card.Body>
            </Accordion.Collapse>
          </Card>

          <Card>
            <Card.Header>
              <Accordion.Toggle as="div" eventKey="5">
                {`CmResidents (${report.CmResidents.length} total)`}
              </Accordion.Toggle>
            </Card.Header>
            <Accordion.Collapse eventKey="5">
              <Card.Body>
                {report.CmResidents.length > 0 ? (
                  <ListGroup variant="flush">
                    {report.CmResidents.map(renderCmProfileSummary)}
                  </ListGroup>
                ) : (
                  <p>No items</p>
                )}
              </Card.Body>
            </Accordion.Collapse>
          </Card>

          <Card>
            <Card.Header>
              <Accordion.Toggle as="div" eventKey="0">
                {`VfFamily (${report.VfFamily.length} unsynced)`}
              </Accordion.Toggle>
            </Card.Header>
            <Accordion.Collapse eventKey="0">
              <Card.Body>
                {report.VfFamily.length > 0 ? (
                  <ListGroup variant="flush">
                    {report.VfFamily.map(renderVfProfileSummary)}
                  </ListGroup>
                ) : (
                  <p>No items</p>
                )}
              </Card.Body>
            </Accordion.Collapse>
          </Card>

          <Card>
            <Card.Header>
              <Accordion.Toggle as="div" eventKey="3">
                {`CmFamily (${report.CmFamily.length} total)`}
              </Accordion.Toggle>
            </Card.Header>
            <Accordion.Collapse eventKey="3">
              <Card.Body>
                {report.CmFamily.length > 0 ? (
                  <ListGroup variant="flush">
                    {report.CmFamily.map(renderCmProfileSummary)}
                  </ListGroup>
                ) : (
                  <p>No items</p>
                )}
              </Card.Body>
            </Accordion.Collapse>
          </Card>

        </Accordion>
      </Card.Body>
    </Card>
  );
};

export default AccessReportCard;
