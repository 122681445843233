import { useState, useCallback, useRef } from 'react';

type Status = 'idle' | 'loading' | 'success' | 'error';

export interface TryAsyncResult<T, A extends any[], E = Error> {
  /** Triggers the async function with provided arguments */
  run: (...args: A) => Promise<T | undefined>
  /** Resets state and invalidates any pending async calls */
  reset: () => void
  /** Retries the last async call with the same arguments */
  retry: () => Promise<T | undefined>
  /** Current status: idle, loading, success, or error */
  status: Status
  /** Boolean indicating if the async function is running */
  loading: boolean
  /** Result from a successful async call (if any) */
  result?: T
  /** Error from a failed async call (if any) */
  error?: E | null
}

export interface UseTryAsyncCallbacks<T, E> {
  /** Called with the result when the async call succeeds */
  onSuccess?: (result: T) => void
  /** Called with the error when the async call fails */
  onError?: (error: E) => void
  /** Called after the async call settles (after success or error) */
  onSettled?: () => void
}

/**
 * A hook for handling async functions with built-in state tracking, retry, and reset capabilities.
 *
 * Optional callback functions for onSuccess, onError, and onSettled can be provided as the second parameter.
 */
function useTryAsync<T, A extends any[], E = Error>(
  asyncFunction: (...args: A) => Promise<T>,
  callbacks?: UseTryAsyncCallbacks<T, E>,
): TryAsyncResult<T, A, E> {
  const [status, setStatus] = useState<Status>('idle');
  const [result, setResult] = useState<T | undefined>(undefined);
  const [error, setError] = useState<E | null>(null);
  const loading = status === 'loading';

  // Counter to track the latest call. Outdated calls will be ignored.
  const callCountRef = useRef(0);
  // Save the latest arguments for retry.
  const lastArgsRef = useRef<A | null>(null);

  const run = useCallback(async (...args: A): Promise<T | undefined> => {
    callCountRef.current += 1;
    const callId = callCountRef.current;
    lastArgsRef.current = args;

    setStatus('loading');
    setError(null);

    try {
      const res = await asyncFunction(...args);
      if (callId === callCountRef.current) {
        setResult(res);
        setStatus('success');
        if (callbacks?.onSuccess) {
          callbacks.onSuccess(res);
        }
        return res;
      }
      return undefined;
    } catch (err) {
      if (callId === callCountRef.current) {
        setError(err as E);
        setStatus('error');
        if (callbacks?.onError) {
          callbacks.onError(err as E);
        }
      }
      return undefined;
    } finally {
      if (callId === callCountRef.current && callbacks?.onSettled) {
        callbacks.onSettled();
      }
    }
  }, [asyncFunction, callbacks]);

  const reset = useCallback(() => {
    // Invalidate any pending calls.
    callCountRef.current += 1;
    setStatus('idle');
    setResult(undefined);
    setError(null);
    lastArgsRef.current = null;
  }, []);

  const retry = useCallback((): Promise<T | undefined> => {
    if (lastArgsRef.current) {
      return run(...lastArgsRef.current);
    }
    return Promise.resolve(undefined);
  }, [run]);

  return {
    run, reset, retry, status, loading, result, error,
  };
}

export default useTryAsync;
