import React, { useEffect, useMemo, useState } from 'react';
import {
  Card, Row, Col, Form, Alert,
  Spinner,
  ListGroup,
} from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useParams, useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import useSWR from 'swr';
import { getGeneratorById, updateGenerator, getFormQuestionsByFormType } from 'api/BroadcastFormAPI';
import { EnterpriseBroadcastFormGeneratorFilter, EnterpriseBroadcastFormGeneratorModelEdit } from 'types/BroadcastForm';
import { Checkbox, Input, Select } from 'components/FormControls';
import { Helmet } from 'react-helmet';
import { selectBroadcastFormTypes } from 'reducers/UserInfo';
import { useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import uuidv4 from 'uuid/v4';
import moment from 'moment';
import { EbfGeneratorFilters } from './EbfGeneratorFilters';

const daysToRespondTooltip = `Sets a limit to how many days a recipient has to respond after first receiving the survey.
                    Leave empty to create a survey that never expires.
                    Changing this value does not retroactively apply to recipients who have already been sent this survey.`;
const onDeletionHookTooltip = 'If selected, this survey will automatically send to a valid recipient when it\'s profile is deleted.';
const hookMessageContentTooltip = 'The message content sent to the recipient when the hook is triggered. Content must contain $$SURVEY_URL$$.';
const defaultHookMessageContent = 'Please complete this survey: $$SURVEY_URL$$';
const numberRegex = /^[0-9]+$/;
const UpdateBroadcastFormGenerator: React.FC = () => {
  const { generatorId } = useParams<{ generatorId: string }>();
  const history = useHistory();
  const {
    handleSubmit, control, formState: { errors }, watch, reset, getValues,
  } = useForm<EnterpriseBroadcastFormGeneratorModelEdit>();
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [filters, setFilters] = useState<EnterpriseBroadcastFormGeneratorFilter[]>([]);
  const formType = watch('FormType');
  const broadcastFormTypeRawOptions = useSelector(selectBroadcastFormTypes);
  const broadcastFormTypeOptions = useMemo(() => {
    if (!formType) return broadcastFormTypeRawOptions;
    return [...broadcastFormTypeRawOptions, { value: formType, label: formType }];
  }, [formType, broadcastFormTypeRawOptions])

  const isOnDeletionHook = watch('OnDeletionHook');
  const swrKey = ['getmultiaccountsurveyquestions', formType];
  const { data: broadcastFormQuestions, isLoading } = useSWR(
    swrKey,
    () => getFormQuestionsByFormType(formType),
    {
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    });

  const { data: generator, isLoading: getGeneratorLoading, error: fetchError } = useSWR(
    ['EbfGenerator', generatorId],
    async () => {
      const response = await getGeneratorById(parseInt(generatorId, 10), {
        ignoreGlobalCatch: true,
      });
      return response;
    },
    {
      shouldRetryOnError: false,
    },
  );

  useEffect(() => {
    if (generator) {
      reset({
        SurveyName: generator.SurveyName,
        Description: generator.Description || '',
        IsActive: generator.IsActive,
        SendToStaff: generator.SendToStaff,
        SendToResidents: generator.SendToResidents,
        SendToFamily: generator.SendToFamily,
        SendEmail: generator.SendEmail,
        SendSms: generator.SendSms,
        FormType: generator.FormType,
        DaysToRespond: generator.DaysToRespond,
        OnDeletionHook: generator.OnDeletionHook,
        HookMessageContent: generator.HookMessageContent ?? defaultHookMessageContent,
        Filters: generator.Filters ?? [],
      });
      setFilters(() => {
        const newFilters = [];
        generator.Filters.map((filter) => newFilters.push({ ...filter, Id: `${uuidv4()}` }));
        return newFilters;
      });
    }
  }, [generator, getGeneratorLoading, reset]);

  const onSubmit = async (formData: EnterpriseBroadcastFormGeneratorModelEdit) => {
    if (!formData.SendToStaff && !formData.SendToResidents && !formData.SendToFamily) {
      setError('At least one recipient group must be selected.');
      return;
    }

    if (!formData.SendEmail && !formData.SendSms) {
      setError('At least one channel (Email or SMS) must be selected.');
      return;
    }

    const fdFilters = formData.Filters;
    let filterError = '';
    const includeFilterFields = [];
    fdFilters?.forEach((filter) => {
      if (filter.FilterType.toLocaleLowerCase() === 'include') {
        if (includeFilterFields.indexOf(filter.FilterField) >= 0) {
          filterError = `Filter Field ${filter.FilterField.split('#')[0].trim()} can not occur multiple times in Include filter.`;
        } else {
          includeFilterFields.push(filter.FilterField);
        }
      }
      if (filter.FilterField.toLowerCase() === 'start date#v1sync' || filter.FilterField.toLowerCase() === 'end date#v1sync') {
        if (!filter.FilterStartDate && !filter.FilterEndDate) {
          filterError = 'The "After" and "Before" values must have at least one value provided.';
        }
        if (filter.FilterStartDate && filter.FilterEndDate) {
          if (moment(filter.FilterEndDate) <= moment(filter.FilterStartDate)) {
            filterError = 'The value of After must be earlier than the value of Before.';
          }
        }
      } else if (filter.FilterValue?.trim() === '') {
        filterError = 'The Filter Value is required.';
      } else if (filter.FilterField.toLowerCase() === 'account id') {
        const accountFilters = filter.FilterValue.split(',');
        accountFilters.forEach((af) => {
          if (!numberRegex.test(af.trim())) {
            filterError = 'The Account Id must be a number.';
          }
        })
      }
    });

    if (filterError) {
      setError(filterError);
      return;
    }
    setLoading(true);
    try {
      const updatedGenerator = await updateGenerator(parseInt(generatorId, 10), { ...formData });
      history.push('/internal/multiaccountsurvey');
      toast.success(`Survey Generator "${updatedGenerator.SurveyName}" successfully updated`);
    } catch (ex) {
      setError(ex.response?.data?.Message ?? 'Failed to update the broadcast form generator.');
    } finally {
      setLoading(false);
    }
  };

  if (fetchError) {
    return <Alert variant="danger">Failed to load the broadcast form generator.</Alert>;
  }

  if (!generator || isLoading) {
    return <p>Loading...</p>;
  }

  const handleAddFilter = () => {
    const formData = getValues();
    const prevFilters = formData.Filters ?? [];
    const newFilter: EnterpriseBroadcastFormGeneratorFilter = {
      FilterId: 0,
      EnterpriseAccountId: generator.EnterpriseAccountId,
      GeneratorId: generator.GeneratorId,
      FilterType: 'Include',
      FilterField: 'Job Classification',
      FilterValue: null,
      FilterStartDate: null,
      FilterEndDate: null,
      Id: `${uuidv4()}`,
    }
    const newFilters = [...prevFilters, newFilter]
    reset({
      SurveyName: formData.SurveyName,
      Description: formData.Description || '',
      IsActive: formData.IsActive,
      SendToStaff: formData.SendToStaff,
      SendToResidents: formData.SendToResidents,
      SendToFamily: formData.SendToFamily,
      SendEmail: formData.SendEmail,
      SendSms: formData.SendSms,
      FormType: formData.FormType,
      DaysToRespond: formData.DaysToRespond,
      OnDeletionHook: isOnDeletionHook,
      HookMessageContent: (isOnDeletionHook && formData.HookMessageContent) ? formData.HookMessageContent : defaultHookMessageContent,
      Filters: newFilters ?? [],
    });
    setFilters(newFilters);
  }

  const handleRemoveFilter = (id: string) => {
    const formData = getValues();
    const prevFormDataFilters = formData?.Filters;
    const newFilters = prevFormDataFilters.filter((filter) => filter.Id !== id);
    reset({
      SurveyName: formData.SurveyName,
      Description: formData.Description || '',
      IsActive: formData.IsActive,
      SendToStaff: formData.SendToStaff,
      SendToResidents: formData.SendToResidents,
      SendToFamily: formData.SendToFamily,
      SendEmail: formData.SendEmail,
      SendSms: formData.SendSms,
      FormType: formData.FormType,
      DaysToRespond: formData.DaysToRespond,
      OnDeletionHook: isOnDeletionHook,
      HookMessageContent: (isOnDeletionHook && formData.HookMessageContent) ? formData.HookMessageContent : defaultHookMessageContent,
      Filters: newFilters ?? [],
    });
    setFilters(newFilters);
  }

  return (
    <div className="EbfGeneratorEdit">
      <Helmet>
        <title>Update Multi-Account Survey Generator</title>
      </Helmet>
      <div className="header-bar">
        <h1>Update Multi-Account Survey Generator</h1>
      </div>
      {error && <Alert variant="danger">{error}</Alert>}
      {errors?.Filters && errors.Filters?.[0]?.FilterValue?.message && <Alert variant="danger">{errors.Filters?.[0]?.FilterValue?.message}</Alert>}
      <Card className="overflow-visible flex-direction-row">
        <Card.Body className="ebfgeneratorcard">
          <form onSubmit={handleSubmit(onSubmit)}>
            <Row>
              <Col xs="12" md="12">
                <Form.Group>
                  <Input
                    id="SurveyName"
                    control={control}
                    type="Text"
                    name="SurveyName"
                    label="Survey Name"
                    required
                    rules={{
                      required: 'Survey Name is required',
                      maxLength: {
                        value: 50,
                        message: 'Survey Name must be 50 characters or less',
                      },
                      validate: (val: string) => {
                        if (val.trim() === '') {
                          return 'Survey Name cannot be empty or whitespace'
                        }
                        const regex = /[\uD800-\uDBFF][\uDC00-\uDFFF]/g;
                        if (regex.test(val)) {
                          return 'Survey Name can not include emojis';
                        }
                        return true;
                      },
                    }}
                    maxLength={50}
                    errors={errors.SurveyName?.message}
                  />
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col xs="12" md="12">
                <Form.Group>
                  <Select
                    id="FormType"
                    name="FormType"
                    label="Survey"
                    placeholder="Select content for survey"
                    required
                    control={control}
                    options={broadcastFormTypeOptions}
                    errors={errors.FormType?.message}
                    rules={{
                      required: 'Survey type is required',
                    }}
                    disabled
                  />
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col xs="12" md="12">
                <Form.Group>
                  <Input
                    id="Description"
                    control={control}
                    type="Text"
                    name="Description"
                    label="Description (optional)"
                    maxLength={600}
                    rules={{
                      maxLength: {
                        value: 600,
                        message: 'Description must be 600 characters or less',
                      },
                      validate: (val: string) => {
                        const regex = /[\uD800-\uDBFF][\uDC00-\uDFFF]/g;
                        if (regex.test(val)) {
                          return 'Description can not include emojis';
                        }
                        return true;
                      },
                    }}
                    errors={errors.Description?.message}
                  />
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col xs="12" md="12">
                <Form.Group>
                  <h4 className="mt-4 mb-4">Active:</h4>
                  <Checkbox
                    id="IsActive"
                    name="IsActive"
                    className="ml-4"
                    label="Is Active"
                    control={control}
                  />
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col xs="12" md="12">
                <Form.Group>
                  <h4 className="mt-4 mb-4">Recipients:</h4>
                  <Checkbox
                    id="SendToStaff"
                    name="SendToStaff"
                    className="ml-4"
                    label="Send to Staff"
                    control={control}
                  />
                  <Checkbox
                    id="SendToResidents"
                    name="SendToResidents"
                    className="ml-4"
                    label="Send to Residents"
                    control={control}
                  />
                  <Checkbox
                    id="SendToFamily"
                    name="SendToFamily"
                    className="ml-4"
                    label="Send to Family"
                    control={control}
                  />
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col xs="12" md="12">
                <Form.Group>
                  <h4 className="mt-4 mb-4">Channels:</h4>
                  <Checkbox
                    id="SendEmail"
                    name="SendEmail"
                    className="ml-4"
                    label="Email"
                    control={control}
                  />
                  <Checkbox
                    id="SendSms"
                    name="SendSms"
                    className="ml-4"
                    label="SMS"
                    control={control}
                  />
                </Form.Group>
              </Col>
            </Row>
            <EbfGeneratorFilters
              data={filters}
              onAddFilter={handleAddFilter}
              onRemoveFilter={handleRemoveFilter}
              loading={loading}
              control={control}
              watch={watch}
              errors={errors}
            />
            <Row>
              <Col xs="12" md="12">
                <Form.Group>
                  <h4 className="mt-4 mb-4">Days To Respond:</h4>
                  <p>
                    {daysToRespondTooltip}
                  </p>
                  <Input
                    id="DaysToRespond"
                    name="DaysToRespond"
                    className="inputMaxWidth"
                    control={control}
                    type="text"
                    label="Days To Respond"
                    maxLength={3}
                    rules={{
                      validate: (val: string) => {
                        if (val === '' || !val) {
                          return true;
                        }
                        const errMessage = 'Must be empty or number between 1 and 365';
                        try {
                          const intVal = parseInt(val, 10);
                          if (isNaN(intVal) || intVal > 365 || intVal < 1) return errMessage;
                        } catch (e) {
                          return errMessage;
                        }
                        return null;
                      },
                    }}
                    errors={errors.DaysToRespond?.message}
                    customIcon={(
                      <FontAwesomeIcon icon="question-circle" size="1x" />
                    )}
                    customIconTooltip={daysToRespondTooltip}
                  />
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col xs="12" md="12">
                <Form.Group>
                  <h4 className="mt-4 mb-4">Hooks:</h4>
                  <Checkbox
                    id="OnDeletionHook"
                    name="OnDeletionHook"
                    className="ml-4"
                    label="On Deletion"
                    control={control}
                    customIcon={(
                      <FontAwesomeIcon icon="question-circle" size="1x" />
                    )}
                    customIconTooltip={onDeletionHookTooltip}
                  />
                  {isOnDeletionHook && (
                    <Input
                      id="HookMessageContent"
                      name="HookMessageContent"
                      className="inputMaxWidth"
                      control={control}
                      type="text"
                      label="Message"
                      maxLength={200}
                      rules={{
                        validate: (val: string) => {
                          if (!isOnDeletionHook) {
                            return true;
                          }
                          if (val === '' || !val) {
                            return 'The Message is required.';
                          }
                          if (val?.indexOf('$$SURVEY_URL$$') === -1) {
                            return 'The Message require merge field: $$SURVEY_URL$$';
                          }
                          return null;
                        },
                      }}
                      errors={errors.HookMessageContent?.message}
                      customIcon={(
                        <FontAwesomeIcon icon="question-circle" size="1x" />
                      )}
                      customIconTooltip={hookMessageContentTooltip}
                    />
                  )}

                </Form.Group>
              </Col>
            </Row>
            <button type="submit" className="btn btn-primary mt-4 mb-4" disabled={loading}>
              {loading ? 'Updating...' : 'Update Generator'}
            </button>
          </form>
        </Card.Body>
        <Card.Body className="questions-card">
          {(isLoading || broadcastFormQuestions == null) && (
            <div
              className="loading-text d-flex align-items-center justify-content-center"
              role="status"
            >
              <Spinner
                animation="border"
                as="span"
                className="mr-2"
                style={{ width: '1.66em', height: '1.66em' }}
              />
              <span style={{ fontSize: '1.25em' }}>
                Loading...
              </span>
            </div>
          )}
          {!isLoading && (
            <div style={{ height: '628.5px', overflowY: 'auto' }}>
              <p>
                Survey Questions (
                {broadcastFormQuestions?.length}
                {' '}
                total)
              </p>
              <ListGroup>
                {broadcastFormQuestions.map((prof) => (
                  <ListGroup.Item
                    key={prof.QuestionId}
                    data-testid="message-recipient"
                    className="tableListItem"
                  >
                    {' '}
                    {prof.QuestionText}
                  </ListGroup.Item>
                ))}
              </ListGroup>
            </div>
          )}
        </Card.Body>
      </Card>
    </div>
  );
};

export default UpdateBroadcastFormGenerator;
