import React from 'react';
import { Textarea } from 'components/FormControls';
import { Control, Controller } from 'react-hook-form'
import { Question, ScaleType } from 'types/BroadcastForm'
import { FormControlLabel, Radio, RadioGroup } from '@material-ui/core';
import Checkbox from 'components/Inputs/Checkbox';
import { ContentLength } from 'components/EventTemplateForm/ContentLength';

interface QuestionProps {
  question: Question
  control: Control
  trigger: (name?: string | string[]) => Promise<boolean>
  getValues: (name?: string|string[]) => any
}

export const FormQuestion = ({
  question, control, trigger, getValues,
}: QuestionProps) => (
  <>
    <p className="broadcast-form-session__question">
      {question.QuestionText}
    </p>
    {question.ScaleType === ScaleType.OpenText && (
      <div>
        <ContentLength
          name={`${question.QuestionId}.Text`}
          maxLength={250}
        />
        <Textarea
          rows={3}
          id={`question-${question.QuestionId}`}
          name={`${question.QuestionId}.Text`}
          control={control}
          maxLength={250}
          rules={{
            required: true,
            validate: (val) => !!val.trim(),
          }}
        />
      </div>
    )}
    {[ScaleType.Boolean, ScaleType.LikertScale, ScaleType.NetPromoter, ScaleType.Custom].includes(question.ScaleType) && (
      <Controller
        name={`${question.QuestionId}.OptionId`}
        rules={{
          required: true,
        }}
        control={control}
        render={({ name, value: selectedValue, onChange }) => (
          <RadioGroup
            name={name}
          >
            {question.Options.map((option) => {
              const isSelected = selectedValue === option.OptionId;

              return (
                <>
                  <FormControlLabel
                    key={option.OptionId}
                    value={option.OptionId}
                    control={<Radio color="primary" />}
                    label={option.OptionText}
                    checked={isSelected}
                    onChange={() => {
                      onChange(option.OptionId);
                      trigger(`${question.QuestionId}.Text`);
                    }}
                  />
                  {option.WithTextInput && (
                    <div className="option-text-input">
                      <ContentLength
                        name={`${question.QuestionId}.Text`}
                        maxLength={250}
                      />
                      <Textarea
                        disabled={selectedValue !== option.OptionId}
                        rows={3}
                        id={`question-${question.QuestionId}-text`}
                        maxLength={250}
                        name={`${question.QuestionId}.Text`}
                        rules={{
                          validate: (val) => {
                            const latestOptionIdVal = getValues(`${question.QuestionId}.OptionId`);
                            const latestIsSelected = latestOptionIdVal === option.OptionId;

                            return latestIsSelected
                              ? !!val.trim()
                              : true
                          },
                        }}
                        control={control}
                        className="mb-0"
                      />
                    </div>
                  )}
                </>
              )
            })}
          </RadioGroup>
        )}
      />
    )}

    {question.ScaleType === ScaleType.MultiSelect && (
      <Controller
        name={`${question.QuestionId}.OptionId`}
        rules={{
          required: true,
        }}
        control={control}
        render={({ name, value: selectedValue, onChange }) => (
          <>
            {question.Options.map((option) => {
              const isChecked = selectedValue?.includes(option.OptionId);

              return (
                <>
                  <Checkbox
                    name={name}
                    key={option.OptionId}
                    id={`question-${question.QuestionId}-option-${option.OptionId}`}
                    label={option.OptionText}
                    checked={isChecked}
                    onChange={(e) => {
                      let newVal = selectedValue ?? [];
                      if (e.target.checked) {
                        newVal = [...newVal, option.OptionId];
                      } else {
                        newVal = newVal.filter((item) => item !== option.OptionId)
                      }
                      onChange(newVal);
                      trigger(`${question.QuestionId}.Text`);
                    }}
                  />
                  {option.WithTextInput && (
                    <div className="option-text-input">
                      <ContentLength
                        name={`${question.QuestionId}.Text`}
                        maxLength={250}
                      />

                      <Textarea
                        disabled={!selectedValue?.includes(option.OptionId)}
                        rows={3}
                        maxLength={250}
                        id={`question-${question.QuestionId}-text`}
                        name={`${question.QuestionId}.Text`}
                        rules={{
                          validate: (val) => {
                            const latestOptionIdVal = getValues(`${question.QuestionId}.OptionId`);
                            const latestIsSelected = latestOptionIdVal?.includes(option.OptionId);

                            return latestIsSelected
                              ? !!val.trim()
                              : true
                          },
                        }}
                        control={control}
                        className="mb-0"
                      />
                    </div>
                  )}
                </>

              )
            })}
          </>
        )}
      />
    )}
  </>
)
