import { IOptions } from 'components/Inputs/Select';
import { useMemo } from 'react';

export const useToOptionsList = (values: string[]) => {
  // takes in a list of strings and convers to memoized list of options
  // with values and labels set to the values provided
  // we stringify the dependencies so that we don't rebuild the options unneccesarily
  const dependencies = JSON.stringify(values);
  const result: IOptions[] = useMemo(() => values.map((value) => ({
    label: value,
    value,
  // eslint-disable-next-line react-hooks/exhaustive-deps
  })), [dependencies]);
  return result;
}
