import React from 'react';
import { IValidationResult, IValidationResultEntry, ValidationLevel } from 'types/IValidationResult';

interface ValidationResultProps {
  result: IValidationResult
  className?: string
}

const ValidationResult: React.FC<ValidationResultProps> = ({ result, className }) => {
  if (!result.Entries.length) return null;

  const getColor = (level: ValidationLevel) => {
    switch (level) {
      case ValidationLevel.Warning:
        return 'text-warning';
      case ValidationLevel.Error:
        return 'text-danger';
      case ValidationLevel.Info:
      default:
        return 'text-dark';
    }
  };

  return (
    <div className={className ? `validation-result ${className}` : 'validation-result'}>
      {result.Entries.map((entry: IValidationResultEntry) => (
        <p key={entry.Message + entry.Level} className={getColor(entry.Level)}>
          {entry.Message}
        </p>
      ))}
    </div>
  );
};

export default ValidationResult;
