import { useEffect, useMemo, useState } from 'react';

/// Helper hook to compare two values deeply and determine if any changes are present
export const useIsModifed = (original: any, updated: any) => {
  const [isModified, setIsModified] = useState<boolean>()

  const originalStringified = useMemo(() => {
    if (!original) return null;
    return JSON.stringify(original);
  }, [original])

  const updatedStringified = useMemo(() => {
    if (!updated) return null;
    return JSON.stringify(updated);
  }, [updated])

  useEffect(() => {
    const hasChanges = originalStringified !== updatedStringified;
    setIsModified(hasChanges);
  }, [updatedStringified, originalStringified]);

  return isModified;
}
