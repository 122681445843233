import React, { FC, ReactElement, useMemo } from 'react';
import { useQueryParam, NumberParam, StringParam } from 'use-query-params';
import Table from 'components/Table';
import Pagination from 'components/Pagination';
import PageSizePicker from 'components/PageSizePicker';
import { useJobsForTenant } from 'hooks/V1SyncHooks';
import { useHistory } from 'react-router-dom';
import { BulkActions } from 'components/BulkActions';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { CellProps } from 'react-table';
import { selectAccountTz } from 'reducers/UserInfo';

interface TenantJobsTableProps {
  tenantId: number
}

const formatDateSentCell = (acctTz: string) => (props: CellProps<Record<string, unknown>>) => {
  const { cell: { value } } = props;

  if (!value) return '';
  const localTime = moment
    .utc(value, 'YYYY-MM-DDTHH:mm:ss.SSS')
    .tz(acctTz);

  return localTime.format('YYYY-MM-DD, h:mm a');
}

export const formatV1SyncJobStatus = (value: string) => {
  let color = 'text-dark';
  let text = value;
  switch (value) {
    case 'Completed':
      color = 'text-success';
      break;
    case 'CompletedWithErrors':
      color = 'text-danger';
      text = 'Completed (Errors)';
      break;
    case 'Failed':
      color = 'text-danger';
      break;
    case 'Canceled':
      color = 'text-danger';
      break;
    case 'CompletedWithWarnings':
      color = 'text-warning';
      text = 'Completed (Warnings)';
      break;
    case 'InProgress':
      color = 'text-info';
      text = 'Running';
      break;
  }

  return (
    <h6 className={`${color} d-inline`}>
      {text}
    </h6>
  );
}

const formatStatus = (props: CellProps<Record<string, unknown>>) => {
  const { cell: { value } } = props;
  return formatV1SyncJobStatus(value);
}

export const TenantJobsTable: FC<TenantJobsTableProps> = ({ tenantId }) => {
  const { data: jobs, error, isLoading } = useJobsForTenant(tenantId);
  const history = useHistory();
  const accountTz = useSelector(selectAccountTz);

  const [pageParam = 1, setPageParam] = useQueryParam('page', NumberParam);
  const [perPageParam = 10, setPerPageParam] = useQueryParam('perpage', NumberParam);
  const [sortFieldParam, setSortFieldParam] = useQueryParam('sortField', StringParam);
  const [sortDirectionParam, setSortDirectionParam] = useQueryParam('sortDirection', StringParam);

  const columns = useMemo(() => [
    {
      Header: 'Status', accessor: 'Status', Cell: formatStatus, sortable: true,
    },
    { Header: 'Job ID', accessor: 'JobId', sortable: true },
    { Header: 'Job Type', accessor: 'JobType', sortable: true },
    {
      Header: 'Started At', accessor: 'StartedAt', Cell: formatDateSentCell(accountTz), sortable: true,
    },
    {
      Header: 'Completed At', accessor: 'CompletedAt', Cell: formatDateSentCell(accountTz), sortable: true,
    },
    { Header: 'Triggered By', accessor: 'TriggeredBy', sortable: true },
  ], [accountTz]);

  const sortedJobs = useMemo(() => {
    if (!jobs) return [];
    const sortDirection = sortDirectionParam === 'asc' ? 1 : -1;
    const sortField = sortFieldParam ?? 'JobId';
    return [...jobs].sort((a, b) => (a[sortField] > b[sortField] ? 1 : -1) * sortDirection);
  }, [jobs, sortFieldParam, sortDirectionParam]);

  const jobsOnPage = useMemo(() => {
    if (!sortedJobs.length) return [];
    const startIndex = (pageParam - 1) * perPageParam;
    return sortedJobs.slice(startIndex, startIndex + perPageParam);
  }, [sortedJobs, pageParam, perPageParam]);

  const totalPages = useMemo(() => {
    if (!sortedJobs.length) return 1;
    return Math.ceil(sortedJobs.length / perPageParam);
  }, [sortedJobs.length, perPageParam]);

  const rowBulkActionItems = (jobId: number): ReactElement => (
    <BulkActions
      className="ml-3"
      items={[{
        label: 'View Logs',
        handler: () => history.push(`/v1sync/${tenantId}/jobs/${jobId}`),
      }]}
    />
  );

  if (error) {
    return (
      <div className="loading-text d-flex align-items-center">
        {error?.response?.data?.Message || 'An error has occurred'}
      </div>
    );
  }

  return (
    <div>
      <Table
        columns={columns}
        enableCheck={false}
        data={jobsOnPage}
        selectButton={undefined}
        rowIDAccessor="JobId"
        isLoading={isLoading}
        rowActionItems={rowBulkActionItems}
        onColumnSort={(fieldName, direction) => {
          setSortFieldParam(fieldName);
          setSortDirectionParam(direction);
        }}
        sortedColumn={sortFieldParam}
        sortedColumnDirection={sortDirectionParam}
      />
      <div className="paging">
        <PageSizePicker
          initialPageSize={perPageParam}
          onSetPageSize={(size) => {
            setPageParam(1, 'replaceIn');
            setPerPageParam(size);
          }}
        />
        <Pagination
          currentPage={pageParam}
          totalPages={totalPages}
          onPageChange={(newPage: number) => setPageParam(newPage, 'pushIn')}
          pageDelta={5}
        />
      </div>
    </div>
  );
};

export default TenantJobsTable;
