import React, {
  FC, ReactElement, useCallback, useMemo, useState,
} from 'react';
import {
  useQueryParam, NumberParam, StringParam,
} from 'use-query-params';
import { BulkActions } from 'components/BulkActions';
import Pagination from 'components/Pagination';
import Table from 'components/Table';
import PageSizePicker from 'components/PageSizePicker';
import IndexFilter, { ISearchQuery } from 'components/Inputs/IndexFilter_Id_Name';
import { DeleteActionModal } from 'components/DeleteActionModal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as BroadcastFormApi from 'api/BroadcastFormAPI';
import useSWRMutation from 'swr/mutation';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import { useTenantsWithInfo } from 'hooks/V1SyncHooks';

type TenantConfigTableProps = {
  showActions?: boolean
};

export const TenantConfigTable: FC<TenantConfigTableProps> = ({
  showActions,
}) => {
  const {
    data: tenantInfos, error, isLoading: tenantInfosLoading, mutate,
  } = useTenantsWithInfo();

  // const accountTz = useSelector(selectAccountTz);
  const [pageParam = 1, setPageParam] = useQueryParam('page', NumberParam);
  const [perPageParam = 10, setPerPageParam] = useQueryParam('perpage', NumberParam);
  const [sortFieldParam, setSortFieldParam] = useQueryParam('sortField', StringParam);
  const [sortDirectionParam, setSortDirectionParam] = useQueryParam('sortDirection', StringParam);
  const [formToDeleteID, setFormToDeleteID] = useState<number|undefined>();
  const [formToCompleteID, setFormToCompleteID] = useState<number|undefined>();

  const history = useHistory();

  const [searchQuery, setSearchQuery] = useQueryParam(
    'searchQuery',
    StringParam,
  );

  const getSearchQuery = useCallback((): ISearchQuery => {
    const query = searchQuery?.split(',');
    if (!query) return {};
    return { searchField: query[0], searchValue: query[1] };
  }, [searchQuery]);

  const onSearch = (searchingQuery: ISearchQuery): void => {
    if (searchingQuery.searchField || searchingQuery.searchValue) {
      setSearchQuery(
        `${searchingQuery.searchField || ''},${searchingQuery?.searchValue || ''}`,
      );
    } else {
      setSearchQuery('');
    }
    setPageParam(1, 'pushIn');
  };

  const resetFilters = (): void => {
    setSearchQuery('', 'pushIn');
    setPageParam(1, 'pushIn');
  };

  const columns = useMemo(() => [
    {
      Header: 'Tenant Id', accessor: 'tenantId', sortable: true, showToolTip: true,
    },
    {
      Header: '# Mappings', accessor: 'syncedCount', sortable: true, showToolTip: true,
    },
    {
      Header: 'Account Names', accessor: 'accountNames', sortable: false, showToolTip: true,
    },
    {
      Header: 'Account Ids', accessor: 'accountIds', sortable: false, showToolTip: true,
    },
    {
      Header: 'Facility Ids', accessor: 'facilityIds', sortable: false, showToolTip: true,
    },
  ].filter(Boolean), []);

  const items = useMemo(() => {
    if (!tenantInfos) return [];

    const { searchField, searchValue } = getSearchQuery();
    const data = Object.entries(tenantInfos).map(([tenantId, info]) => {
      if (searchField === 'tenantId' && `${tenantId}` !== searchValue) {
        return null;
      }

      const accountIds = info?.Assocs?.map((a) => a.AccountId) ?? [];
      if (searchField === 'accountId' && !accountIds.includes(Number(searchValue))) {
        return null;
      }

      const facilityIds = info?.Assocs?.map((a) => a.CaremergeFacilityId) ?? [];
      if (searchField === 'facilityId' && !facilityIds.includes(Number(searchValue))) {
        return null;
      }

      const accountNames = info?.Accounts?.map((a) => a.Name);
      if (searchField === 'accountName'
        && accountNames.findIndex((a) => a.toLowerCase().includes(searchValue?.toLowerCase())) < 0) {
        return null;
      }

      return {
        tenantId,
        accountNames: accountNames.join() ?? '',
        accountIds: accountIds.join() ?? '',
        facilityIds: facilityIds.join() ?? '',
        syncedCount: info?.Assocs?.length ?? 0,
      };
    }).filter((x) => !!x);

    const sortDirection = sortDirectionParam === 'desc' ? -1 : 1;
    return data.sort((a, b) => (a[sortFieldParam] - b[sortFieldParam]) * sortDirection);
  }, [tenantInfos, sortFieldParam, sortDirectionParam, getSearchQuery]);

  const itemsOnPage = useMemo(() => {
    if (!items || items.length === 0) return [];
    const startIndex = (pageParam - 1) * perPageParam;
    const endIndex = startIndex + perPageParam;
    return items.slice(startIndex, endIndex);
  }, [items, pageParam, perPageParam]);

  const totalPages = useMemo(() => {
    if (!(items?.length)) return 1;
    return Math.ceil(items.length / perPageParam);
  }, [items.length, perPageParam]);

  const updateBroadcastFormStatus = async (url, { arg }: {arg: { formId: number, isComplete: boolean }}) => {
    await BroadcastFormApi.completeBroadcastForm(arg.formId, arg.isComplete);
  }
  const { trigger: updateStatus } = useSWRMutation('updateStatus', updateBroadcastFormStatus, {
    revalidate: true,
    populateCache: false,
    onSuccess: () => {
      setFormToCompleteID(undefined);
      toast.success('Broadcast form successfully completed');
      mutate();
    },
  });

  const deleteBroadcastForm = async (url, { arg }: {arg: { formId: number }}) => {
    await BroadcastFormApi.broadcastFormDelete(arg.formId);
  }

  const { trigger: deleteForm } = useSWRMutation('deletebroadcastform', deleteBroadcastForm, {
    revalidate: true,
    populateCache: false,
    onSuccess: () => {
      setFormToDeleteID(undefined);
      toast.success('Broadcast form successfully deleted');
      mutate();
    },
  });

  const handleDelete = () => {
    deleteForm({ formId: formToDeleteID });
  }

  const handleMarkComplete = () => {
    updateStatus({ formId: formToCompleteID, isComplete: true });
  }

  const renderIndexFilter = (): ReactElement => (
    <IndexFilter
      searchQuery={getSearchQuery()}
      onSearch={onSearch}
      onResetFilters={resetFilters}
      title="Tenant Configuration"
      totalItems={items.length}
      itemsLoading={tenantInfosLoading}
      defaultField="tenantId"
      excludeDefaultOptions
      options={[
        { label: 'Tenant ID', value: 'tenantId' },
        { label: 'Account Name', value: 'accountName' },
        { label: 'Account ID', value: 'accountId' },
        { label: 'Facility ID', value: 'facilityId' },
      ]}
    />
  );

  const rowBulkActionItems = (tenantId: number): ReactElement => {
    const actionItems = [
      {
        label: 'Edit Config',
        handler: () => {
          history.push(`/v1sync/edit/${tenantId}`);
        },
      },
      {
        label: 'Scheduled Jobs',
        handler: () => {
          history.push(`/v1sync/${tenantId}/jobs`);
        },
      },
      {
        label: 'Add Facility',
        handler: () => {
          history.push(`/v1sync/${tenantId}/add`);
        },
      },
    ];
    // if (isActiveParam) {
    //   actionItems.push({
    //     label: 'Resend',
    //     handler: () => {
    //       history.push(`/feedback360/${entityId}/resent`);
    //     },
    //   });
    //   // actionItems.push({
    //   //   label: 'Mark Completed',
    //   //   handler: () => {
    //   //     // setFormToCompleteID(entityId);
    //   //   },
    //   // });
    //   // actionItems.push({
    //   //   label: 'Delete',
    //   //   handler: () => {
    //   //     // setFormToDeleteID(entityId);
    //   //   },
    //   //   className: 'delete-action',
    //   // });
    // }
    return (
      <BulkActions
        className="ml-3"
        items={actionItems}
      />
    );
  };

  if (error) {
    return (
      <div className="loading-text d-flex align-items-center">
        {error?.response?.data?.Message || 'An error has occurred'}
      </div>
    );
  }

  return (
    <div>
      {renderIndexFilter()}
      <Table
        columns={columns}
        enableCheck={false}
        data={itemsOnPage}
        selectButton={undefined}
        rowIDAccessor="tenantId"
        isLoading={tenantInfosLoading}
        rowActionItems={showActions ? rowBulkActionItems : undefined}
        onColumnSort={(fieldName, direction) => {
          if (fieldName.length > 0) {
            setSortFieldParam(fieldName);
            setSortDirectionParam(direction);
          } else {
            setSortFieldParam('');
            setSortDirectionParam('');
          }
        }}
        sortedColumn={sortFieldParam}
        sortedColumnDirection={sortDirectionParam}
        className="Template-Table"
      />
      <div className="paging">
        <PageSizePicker
          initialPageSize={perPageParam}
          onSetPageSize={(size) => {
            setPageParam(1, 'replaceIn');
            setPerPageParam(size);
          }}
        />
        <Pagination
          currentPage={pageParam}
          totalPages={totalPages ?? 1}
          onPageChange={(newPage: number) => setPageParam(newPage, 'pushIn')}
          pageDelta={5}
        />
      </div>
      <DeleteActionModal
        isOpen={formToDeleteID !== undefined}
        title="Are you sure?"
        onCancel={() => setFormToDeleteID(undefined)}
        onSuccess={() => {
          handleDelete();
          setFormToDeleteID(undefined);
        }}
      />
      <DeleteActionModal
        isOpen={formToCompleteID !== undefined}
        title="Are you sure?"
        onCancel={() => setFormToCompleteID(undefined)}
        onSuccess={() => {
          handleMarkComplete();
          setFormToCompleteID(undefined);
        }}
        buttonName="Mark Completed"
        deleteButtonClassName="btn-primary"
        customIcon={(
          <FontAwesomeIcon
            icon="exclamation-circle"
            size="4x"
            color="#FFAE42"
          />
        )}
      >
        <div>
          Once a survey has been marked completed no more responses can be submitted. This cannot be undone
        </div>
      </DeleteActionModal>
    </div>
  );
};
