import React, { useMemo, useState } from 'react';
import { Button, Spinner } from 'react-bootstrap';
import { AccountFacilityMapping, V1SyncJobModel, V1SyncJobRequest } from 'types/V1SyncTypes';
import SelectInput, { IOptions } from 'components/Inputs/Select';
import { useTenantsWithInfo } from 'hooks/V1SyncHooks';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import useTryAsync from 'hooks/useTryAsync';
import MultiSelectInput from 'components/Inputs/MultiSelectInput';
import Checkbox from 'components/Inputs/Checkbox';

interface IJobOptionsWithRequiredFields extends IOptions {
  description: string
  requiresFacilityId: boolean
  requiresCommPrefSettings?: boolean
}

const V1SyncJobOptions: IJobOptionsWithRequiredFields[] = [
  {
    label: 'Pull Tenant',
    value: 'Pull Tenant',
    requiresFacilityId: false,
    description: 'Pulls entire facility for every active facility mapping in this tenant',
  },
  {
    label: 'Pull Facility',
    value: 'Pull Facility',
    requiresFacilityId: true,
    description: 'Pulls data for a specific facility in this tenant',
  },
  {
    label: 'Pull Facility Staff Profiles',
    value: 'Pull Facility Staff Profiles',
    requiresFacilityId: true,
    description: 'Pulls all staff profiles for a specific facility',
  },
  {
    label: 'Pull Facility Residents Profiles',
    value: 'Pull Facility Residents Profiles',
    requiresFacilityId: true,
    description: 'Pulls all resident profiles for a specific facility',
  },
  {
    label: 'Pull Facility Family Profiles',
    value: 'Pull Facility Family Profiles',
    requiresFacilityId: true,
    description: 'Pulls all family member profiles for a specific facility',
  },
  {
    label: 'Pull Facility Groups',
    value: 'Pull Facility Groups',
    requiresFacilityId: true,
    description: 'Pulls all groups for a specific facility',
  },
  {
    label: 'Remove Facility Mapping',
    value: 'Remove Facility Mapping',
    requiresFacilityId: true,
    description: `Removes a facility-account mapping, deactivates the v1 sync for that account,
      and removes all profile and group associations`,
  },
  {
    label: 'Enable Comms Preferences',
    value: 'Enable Comms Preferences',
    requiresFacilityId: true,
    requiresCommPrefSettings: true,
    description: `Enable all communication preferences for the selected usertype(s) 
      in the selected facility(s). This updates the preferences in a synchronized way so that they'll
      be enabled on both the comms and engagement platforms`,
  },
];

interface V1SyncJobLauncherProps {
  tenantId: number
  activeJob?: V1SyncJobModel
  onLaunchJob: (jobInfo: V1SyncJobRequest) => Promise<number>
}

const V1SyncJobLauncher: React.FC<V1SyncJobLauncherProps> = ({ tenantId, activeJob, onLaunchJob }) => {
  const history = useHistory();
  const [jobType, setJobType] = useState<string>(V1SyncJobOptions[0].value);
  const [accountFacilityMappings, setAccountFacilityMappings] = useState<AccountFacilityMapping[]>([]);
  const [enableStaffCommsPreferences, setEnableStaffCommsPreferences] = useState<boolean>();
  const [enableResidentCommsPreferences, setEnableResidentCommsPreferences] = useState<boolean>();
  const [enableFamilyCommsPreferences, setEnableFamilyCommsPreferences] = useState<boolean>();

  const selectedJobType = V1SyncJobOptions.find((x) => x.value === jobType)

  const { data: allTenants } = useTenantsWithInfo();

  const tenantAccounts = useMemo(() => {
    if (!allTenants) return [];
    const tenantInfo = allTenants[tenantId];
    if (!tenantInfo) return [];

    return tenantInfo.Assocs?.map((a) => {
      const accountName = tenantInfo.Accounts.find((acc) => acc.Id === a.AccountId)?.Name ?? '';
      return {
        facilityId: a.CaremergeFacilityId,
        accountId: a.AccountId,
        accountName,
        value: `${a.CaremergeFacilityId}`,
        label: `${accountName} -- FacilityId(${a.CaremergeFacilityId}), AccountId(${a.AccountId})`,
      }
    }) ?? [];
  }, [allTenants, tenantId]);

  const launchJob = useTryAsync(
    async () => onLaunchJob({
      JobType: jobType,
      Args: {
        AccountFacilityMappings: accountFacilityMappings,
        EnableStaffCommsPreferences: enableStaffCommsPreferences,
        EnableResidentCommsPreferences: enableResidentCommsPreferences,
        EnableFamilyCommsPreferences: enableFamilyCommsPreferences,
      },
    }),
    {
      onSuccess: (result) => {
        toast.success(`Successfully started add facility job ${result}`);
        history.push(`/v1sync/${tenantId}/jobs/${result}`);
      },
      onError: (error: any) => toast.error(error?.response?.data?.Message ?? 'Something went wrong'),
    },
  );

  return (
    <div>
      <SelectInput
        id="Select Job Type"
        name="Select Job Type"
        options={V1SyncJobOptions}
        value={selectedJobType.value}
        onChange={(value) => setJobType(value)}
      />
      <p>{selectedJobType.description}</p>
      {selectedJobType.requiresFacilityId && (
        <MultiSelectInput
          id="Select Facility(s)"
          name="Select Facility(s)"
          options={tenantAccounts}
          value={accountFacilityMappings?.map((x) => `${x.FacilityId}`) ?? []}
          onChange={(v) => setAccountFacilityMappings(tenantAccounts.filter((x) => v.includes(`${x.facilityId}`))
            .map((x) => ({ AccountId: x.accountId, FacilityId: x.facilityId })))}
        />
      )}
      {selectedJobType.requiresCommPrefSettings && (
        <>
          <Checkbox
            className="mt-4"
            label="Enable all communication preferences for Staff"
            name="EnableStaff"
            onChange={(e: any) => setEnableStaffCommsPreferences(e.target.checked)}
            checked={enableStaffCommsPreferences}
          />
          <Checkbox
            className="mt-4"
            label="Enable all communication preferences for Residents"
            name="EnableResidents"
            onChange={(e: any) => setEnableResidentCommsPreferences(e.target.checked)}
            checked={enableResidentCommsPreferences}
          />
          <Checkbox
            className="mt-4"
            label="Enable all communication preferences for Family"
            name="EnableFamily"
            onChange={(e: any) => setEnableFamilyCommsPreferences(e.target.checked)}
            checked={enableFamilyCommsPreferences}
          />
        </>
      )}
      <div className="d-flex align-items-center mt-3">
        <Button variant="primary" onClick={launchJob.run} disabled={!!activeJob || launchJob.loading}>
          Launch Job
        </Button>
        <Spinner
          hidden={!launchJob.loading}
          as="span"
          animation="border"
          variant="primary"
          size="sm"
          className="ml-2"
        />
      </div>
      {!!activeJob && <h6 className="text-info mt-2"> A job is already running</h6> }
    </div>
  );
};

export default V1SyncJobLauncher;
