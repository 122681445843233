import React from 'react';
import { Switch, Route } from 'react-router-dom';

import V1SyncPage from 'pages/V1Sync/Index';
import V1SyncTenantConfigEditPage from 'pages/V1Sync/V1SyncTenantConfigEditPage';
import TenantJobsPage from 'pages/V1Sync/TenantJobsPage';
import V1SyncJobInfoPage from 'pages/V1Sync/V1SyncJobInfoPage';
import V1SyncAddFacilityPage from 'pages/V1Sync/V1SyncAddFacilityPage';

function V1SyncRoutes() {
  return (
    <Switch>
      <Route path="/v1sync/edit/:tenantId" component={V1SyncTenantConfigEditPage} />
      <Route path="/v1sync/:tenantId/add" component={V1SyncAddFacilityPage} />
      <Route path="/v1sync/:tenantId/jobs/:jobId" component={V1SyncJobInfoPage} />
      <Route path="/v1sync/:tenantId/jobs" component={TenantJobsPage} />
      <Route path="/v1sync" component={V1SyncPage} />
    </Switch>
  );
}

export default V1SyncRoutes;
