import React, {
  ReactElement, useEffect, useState,
} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useForm } from 'react-hook-form';
import clsx from 'clsx';
import { Select, SearchInput } from 'components/FormControls';

export interface ISearchQuery {
  searchField?: string
  searchValue?: string
}

interface IProps {
  title: string
  searchQuery: ISearchQuery
  defaultField?: string
  onSearch: (searchQuery: ISearchQuery) => void
  onResetFilters: () => void
  totalItems?: number
  itemsLoading?: boolean
  showTotalItems?: boolean
  children?: React.ReactNode
  options?: { label: string, value: string }[]
  excludeDefaultOptions?: boolean
}

function IndexFilter({
  searchQuery, onSearch, onResetFilters, title, defaultField, totalItems, itemsLoading, children,
  options, showTotalItems = true, excludeDefaultOptions = false,
}: IProps): ReactElement {
  const {
    control, reset, setValue, handleSubmit,
  } = useForm<ISearchQuery>(
    { defaultValues: { ...searchQuery } },
  );

  useEffect(() => {
    reset(searchQuery);
    setTempQuery(searchQuery);
  }, [reset, searchQuery]);

  const [open, setOpen] = useState<boolean>(false);
  const [tempQuery, setTempQuery] = useState<ISearchQuery>(searchQuery);

  const onSearchSubmit = (data: ISearchQuery): void => {
    data.searchValue ? onSearch(data) : onSearch({});
  };

  const resetFilters = (): void => {
    setValue('searchValue', '');
    setValue('searchField', defaultField);
    onResetFilters();
    setOpen(false);
  };

  const [inputType, setInputType] = useState('text');

  useEffect(() => {
    if (tempQuery.searchField && tempQuery.searchField === 'id') {
      setInputType('number');
      setValue('searchValue', '');
      return;
    }
    setInputType('text');
  }, [tempQuery.searchField, setValue]);

  const handleTempQueryChange = (key: keyof ISearchQuery, val: string) => {
    setValue(key, val);

    setTempQuery({
      ...tempQuery,
      [key]: val,
    });
  };

  return (
    <div className="index-filter">
      <div className="index-filter__mobile-toggle" role="presentation" onClick={() => setOpen(!open)}>
        Filtering
        <FontAwesomeIcon icon={open ? 'caret-down' : 'caret-right'} />
      </div>
      <form id="form" onSubmit={handleSubmit(onSearchSubmit)}>
        <div
          className={clsx('index-filter__main', open && 'open')}
        >
          <Select
            id="searchField"
            label="Search By"
            name="searchField"
            className="mb-2 mr-2"
            style={{ width: '160px' }}
            control={control}
            defaultValue={defaultField}
            onChange={(v) => {
              handleTempQueryChange('searchField', v)
            }}
            options={[
              ...(!excludeDefaultOptions ? [{ label: 'Name', value: 'name' },
                { label: 'Description', value: 'desc' }] : []),
              ...(options ?? []),
            ]}
          />

          <div className="d-flex align-items-end mb-2" style={{ maxWidth: '100%' }}>
            <SearchInput
              id="searchValue"
              label={`Search ${title}`}
              placeholder="I am looking for..."
              name="searchValue"
              type={inputType}
              className="mb-0"
              onChange={(e) => {
                handleTempQueryChange('searchValue', e.target.value)
              }}
              control={control}
            />
            <button
              type="submit"
              className="btn btn-md btn-primary square ml-3"
            >
              Search
            </button>
            <button
              type="button"
              className="btn btn-md btn-link ml-3 px-0"
              onClick={resetFilters}
            >
              Cancel
            </button>
          </div>
        </div>
        <div className="flex-grow-1 d-none d-md-block" />
        {showTotalItems && itemsLoading && (
          <div className="index-filter__item-count mb-2">
            <div className="itemCount loading-text">
              Loading...
            </div>
          </div>
        )}
        {showTotalItems && !itemsLoading && (
          <div className="index-filter__item-count mb-2">
            <div className="itemCount">
              {`${totalItems} ${title}(s) In Total`}
            </div>
          </div>
        )}
        {children}
      </form>
    </div>
  );
}

export default IndexFilter;
