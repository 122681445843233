import { ICaremergeTenantAccount } from './ICaremergeTenantAccount';
import { IValidationResult } from './IValidationResult';

export enum CaremergeEmailSyncOption {
  UserEmail = 'UserEmail',
  ContactEmail = 'ContactEmail',
  NoEmail = 'NoEmail',
}

export enum CaremergeEnvConfigOption {
  Staging = 'qa',
  Production = 'production',
}

export enum CaremergeDynamicFieldNames {
  DateOfBirth = 'dob',
  DateOfHire = 'doh',
}

export enum AdditionalFieldSyncOptions {
  JobClassificationToDepartment = 'JobClassificationToDepartment',
  RoleToCmRoleTitle = 'RoleToCmRoleTitle',
  JobClassification = 'JobClassification',
  ResidentServiceLevel = 'Service Level#v1sync',
  StartDate = 'Start Date#v1sync',
  EndDate = 'End Date#v1sync',
  EmployeeStatus = 'Employee Status#v1sync',
  EmployeeType = 'Employee Type#v1sync',
  TerminationReason = 'Termination Reason#v1sync',
}

export type CaremergePhoneNumberFormat = '(###) ###-####'
| '###-###-####'
| '### ### ####';

export interface CaremergeConfiguration {
  ImportType: string
  Env: CaremergeEnvConfigOption
  TenantID: number
  CaremergeSyncUserId: number
  SyncStatus: 'SyncOn' | 'SyncOff'
  CaremergePhoneNumberFormat: CaremergePhoneNumberFormat
  StaffEmailSyncOption: CaremergeEmailSyncOption
  FamilyEmailSyncOption: CaremergeEmailSyncOption
  ResidentEmailSyncOption: CaremergeEmailSyncOption
  LifeDate1Mapping?: CaremergeDynamicFieldNames
  LifeDate2Mapping?: CaremergeDynamicFieldNames
  IconUsersOnly: boolean
  AdditionalFieldSync: AdditionalFieldSyncOptions[]
  Facilities: CaremergeFacilityConfig[]
  InactivePeriodConfigs?: CaremergeInactivePeriodSyncConfig[]
}

export interface CaremergeFacilityConfig {
  CMFacilityId: number
  VFAccountId: number
  VFApiKey: string | null
}

export interface CaremergeInactivePeriodSyncConfig {
  FieldName: AdditionalFieldSyncOptions
  InactiveFieldValues: string[]
}

export interface ICaremergeTenantAssocInfo {
  Assocs: ICaremergeAccountAssociation[]
  Accounts: ICaremergeTenantAccount[]
}

export interface ICaremergeAccountAssociation {
  AccountId: number
  CaremergeFacilityId: number
  CaremergeTenantId: number
}

export interface AccountFacilityMapping
{
  FacilityId: number
  AccountId: number
}

export interface V1SyncJobArgs
{
  AccountFacilityMappings?: AccountFacilityMapping[]
  EnableStaffCommsPreferences?: boolean
  EnableResidentCommsPreferences?: boolean
  EnableFamilyCommsPreferences?: boolean
}

export interface V1SyncJobRequest {
  JobType: string
  Args: V1SyncJobArgs
}

export interface V1SyncJobModel {
  JobId: number
  TenantId: number
  HangfireJobId: string
  JobType: string
  Status: string
  IsCanceled: boolean
  StartedAt: string
  CompletedAt?: string
  TriggeredBy: string
  Args?: V1SyncJobArgs
}

export interface V1SyncJobWithLogs {
  Info: V1SyncJobModel
  Logs: V1SyncJobLogModel[]
}

export interface V1SyncJobLogModel {
  JobLogId: number
  JobId: number
  Timestamp: string
  Level: string
  Message: string
}

export interface V1SyncedAccountModel
{
  AccountId: number
  FacilityId?: number
  TenantId?: number
  AccountName: string
  IsSynced: boolean
}

export interface CmUserProfileSummary {
  FacilityId: number
  UserId: number
  FirstName: string
  LastName: string
  Email: string
  ContactEmail: string
  Phone: string
  Mobile: string
  FullName: string
  UserType: string
}

export interface V1SyncVfProfileSummary {
  AccountID: number
  Id: number
  FirstName: string
  LastName: string
  EmailAddress: string
  PhoneNumber: string
  Mobile: string
  CustomerProfileID: string
  UserType: string
}

export interface V1SyncMatchResult {
  Similarity: number
  VfProfile: V1SyncVfProfileSummary
  CmUser: CmUserProfileSummary
  ReportRecord: string
}

export interface V1SyncMatchingReport {
  ResidentMatches: number
  StaffMatches: number
  FamilyMatches: number

  VfResidentTotal: number
  VfFamilyTotal: number
  VfStaffTotal: number

  CmResidentTotal: number
  CmFamilyTotal: number
  CmStaffTotal: number

  Matching: V1SyncMatchResult[]
}

export interface V1SyncAddFacilityOptions {
  TenantId: number
  FacilityId: number
  AccountId: number
  ShouldMergeData: boolean
  MergeThreshold: number
  DeleteUnsyncedFamily: boolean
  DeleteUnsyncedStaff: boolean
  DeleteUnsyncedResidents: boolean
  ShouldPushResidents: boolean
  ShouldPushStaff: boolean
  ShouldPushFamily: boolean
}

export interface V1SyncCheckAccessReport
{
  Message: string
  ValidationResult: IValidationResult

  VfFamily: V1SyncVfProfileSummary[]
  VfStaff: V1SyncVfProfileSummary[]
  VfResidents: V1SyncVfProfileSummary[]

  CmFamily: CmUserProfileSummary[]
  CmStaff: CmUserProfileSummary[]
  CmResidents: CmUserProfileSummary[]
}
