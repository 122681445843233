import { ICaremergeTenantAccount } from 'types/ICaremergeTenantAccount';
import { BroadcastFormAccountConfiguration } from 'types/BroadcastForm';
import API from './API';
import {
  IAuthResult,
  IUserAccount,
  IUserInfo,
  ILanguage,
  IOAuthResult,
  IAccountIntegration,
} from '../reducers/IUserInfoState';

export const API_PATH = '/api/v2/user';
export const API_ACCOUNT_PATH = '/api/v2/accounts';
export const USER_INFO_PATH = `${API_PATH}/info`;
export const USER_AUTH_PATH = `${API_PATH}/auth`;
export const USER_OAUTH_PATH = 'api/auth/token';
export const USER_ACCOUNT_SELECTION_PATH = `${API_PATH}/accountSelection`;
export const USER_EXCHANGE_TOKEN = `${API_PATH}/exchangeToken`;
export const USER_ACCOUNTS_PATH = `${API_PATH}/accounts`;
export const USER_GREETING_PATH = `${API_PATH}/usergreeting`;
export const USER_LANGUAGES_PATH = `${API_PATH}/languages`;

export async function authenticateUser(
  UserName: string,
  Password: string,
): Promise<IOAuthResult> {
  const response = await API.post<IOAuthResult>(USER_OAUTH_PATH, {
    userName: UserName,
    password: Password,
    grant_type: 'password',
  });
  if (response.status === 401) {
    throw response;
  }
  return response.data;
}

export async function authenticateUserWithResetKey(
  ResetKey: string,
): Promise<IAuthResult> {
  const response = await API.get<IAuthResult>(`${API_PATH}/resetkey?resetKey=${ResetKey}`);
  if (response.status !== 200) {
    throw response;
  }
  return response.data;
}

export async function changeCurrentAccount(
  accountId: number,
): Promise<IAuthResult> {
  const response = await API.post<IAuthResult>(USER_ACCOUNT_SELECTION_PATH, {
    AccountId: accountId,
  });
  return response.data;
}

export async function exchangeToken(): Promise<IAuthResult> {
  const response = await API.post<IAuthResult>(USER_EXCHANGE_TOKEN);
  return response.data;
}

export async function getUserAccounts(): Promise<IUserAccount[]> {
  const response = await API.get<IUserAccount[]>(USER_ACCOUNTS_PATH);
  return response.data;
}

export async function getUserInfo(): Promise<IUserInfo> {
  const response = await API.get<IUserInfo>(USER_INFO_PATH);
  return response.data;
}

export async function getUserLanguages(): Promise<ILanguage[]> {
  const response = await API.get<ILanguage[]>(USER_LANGUAGES_PATH);
  return response.data;
}

export async function getCurrentUserInfo(): Promise<Record<string, any>> {
  return (await API.get<Record<string, any>>(API_PATH)).data;
}

export async function changePassword(password: string): Promise<IAuthResult> {
  return (await API.post<IAuthResult>(`${API_PATH}/changePassword`, { Password: password })).data;
}

export async function sendForgotPassword(username: string): Promise<IAuthResult> {
  return (await API.post<IAuthResult>(`${API_PATH}/passwordreset`, { UserName: username })).data;
}
export async function activationKeyValidate(key: string): Promise<IAuthResult> {
  const response = await API.get<IAuthResult>(`${API_PATH}/activate/${key}/validate`);
  if (response.status !== 204) {
    return { Token: '', Success: false };
  }
  return { Token: '', Success: true };
}
export async function activateUserLogin(key: string, pwd: string): Promise<IAuthResult> {
  const response = await API.put<IAuthResult>(`${API_PATH}/activate`, { Key: key, Password: pwd, Confirm: pwd });
  if (response.status !== 302) {
    return response.data;
  }
  return null;
}
export async function passwordValidate(password: string): Promise<IAuthResult> {
  return (await API.post<IAuthResult>(`${API_PATH}/validatePassword`, { Password: password })).data;
}
export async function logout(): Promise<void> {
  await API.post<IAuthResult>(`${API_PATH}/logout`);
}
export async function getAccountSetting(settingname: string): Promise<string> {
  const response = await API.get<string>(`${API_ACCOUNT_PATH}/settings/accountsettings/${settingname}`);
  return response.data;
}
export async function saveAccountSetting(settingname: string, settingvalue: string): Promise<void> {
  const setting = { SettingName: settingname, SettingValue: settingvalue };
  await API.put<void>(`${API_ACCOUNT_PATH}/settings/accountsettings/${settingname}`, setting);
}

export const getCaremergeTenantAccounts = async (): Promise<ICaremergeTenantAccount[]> => {
  const response = await API.get<ICaremergeTenantAccount[]>('api/v2/caremerge/tenant/accounts');
  return response.data;
}
export const getEnabledFeatures = async (): Promise<string[]> => {
  const response = await API.get<string[]>('api/v2/user/enabled-features');
  return response.data;
}
export async function refreshToken(
  token: string,
): Promise<IOAuthResult> {
  const response = await API.post<IOAuthResult>(USER_OAUTH_PATH, {
    Refresh_Token: token,
    grant_type: 'refresh_token',
  });
  if (response.status === 401) {
    throw response;
  }
  return response.data;
}

export async function updateAccountCommunityName(
  communityName: string,
): Promise<void> {
  await API.put<void>(`${API_ACCOUNT_PATH}/settings/accountmergefield`, {
    CommunityName: communityName?.trim(),
  });
}

export async function getAccountPermittedDomain(): Promise<string[]> {
  const response = await API.get<string[]>('api/v2/admin/permitteddomain');
  return response.data;
}

export async function setAccountPermittedDomain(
  domainList: string[],
): Promise<string[]> {
  const response = await API.post<string[]>('api/v2/admin/permitteddomain', { PermittedDomains: domainList });
  return response.data;
}

export async function unsubscribeEmailAddress(
  jwt: string,
): Promise<void> {
  await API.post<void>('/api/v2/unsubscribe', undefined, { headers: { UnsubscribeEmailJwt: jwt } });
}

export async function getIsUnsubscribe(
  jwt: string,
): Promise<boolean> {
  const response = await API.get<boolean>('/api/v2/unsubscribe', { headers: { UnsubscribeEmailJwt: jwt } });
  return response.data;
}

export async function resubscribeEmailAddress(
  jwt: string,
): Promise<void> {
  await API.post<void>('/api/v2/unsubscribe/cancel', undefined, { headers: { UnsubscribeEmailJwt: jwt } });
}

export async function getSenderReservedDomain(): Promise<string[]> {
  const response = await API.get<string[]>('api/v2/admin/reserveddomain');
  return response.data;
}

export async function setSenderReservedDomain(
  domainList: string[],
): Promise<string[]> {
  const response = await API.post<string[]>('api/v2/admin/reserveddomain', { ReservedDomains: domainList });
  return response.data;
}

export async function getBroadcastFormsAccountConfig(): Promise<BroadcastFormAccountConfiguration> {
  const response = await API.get<BroadcastFormAccountConfiguration>('api/v2/admin/broadcastformaccountconfig');
  return response.data;
}

export async function saveBroadcastFormsAccountConfig({
  senderName,
  senderEmail,
  replyEmail,
  brandColor,
  brandLogo,
  parentAccountId,
  enabled,
  backgroundColor,
  notificationRecipients,
  restrictedBroadcastFormAccess,
  preventAnonymousResponses,
},
): Promise<void> {
  const payload = {
    SenderDisplayName: senderName,
    SenderEmailAddress: senderEmail,
    ReplyEmailAddress: replyEmail,
    BrandColor: brandColor,
    BrandLogoContent: brandLogo,
    ParentAccountId: parentAccountId,
    Enabled: enabled,
    BackgroundColor: backgroundColor,
    NotificationRecipients: notificationRecipients,
    RestrictedBroadcastFormAccess: restrictedBroadcastFormAccess,
    PreventAnonymousResponses: preventAnonymousResponses,
  }
  await API.post('api/v2/admin/broadcastformaccountconfig', payload);
}

export async function getAccountIntegrations(): Promise<IAccountIntegration[]> {
  const response = await API.get<IAccountIntegration[]>('api/v2/admin/getaccountintegrations');
  return response.data;
}

export async function saveAccountIntegrations(
  integrations: IAccountIntegration[],
): Promise<IAccountIntegration[]> {
  const response = await API.post('api/v2/admin/getaccountintegrations', integrations);
  return response.data;
}

export async function getRestrictedBroadcastForms(): Promise<string[]> {
  const response = await API.get<string[]>('api/v2/admin/getrestrictedbroadcastforms');
  return response.data;
}
