import React, {
  FC,
  ReactElement,
} from 'react';
import { Input, RawDateInput, Select } from 'components/FormControls';
import { Col, Form, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { EnterpriseBroadcastFormGeneratorFilter } from 'types/BroadcastForm';
import { IOptions } from 'components/Inputs/Select';
import { useSelector } from 'react-redux';
import { selectCustomFieldNames } from 'reducers/UserInfo';
import { Control } from 'react-hook-form';

type EbfGeneratorFilterProps = {
  filter: EnterpriseBroadcastFormGeneratorFilter
  index: number
  onRemoveFilter: (Id: string) => void
  control: Control
  watch: any
  errors: any
};

const filterTypeOptions: IOptions[] = [{ label: 'Include', value: 'Include' }, { label: 'Exclude', value: 'Exclude' }];
const validateCustomFields = ['Employee Status#v1sync', 'Employee Type#v1sync', 'Termination Reason#v1sync', 'Service Level#v1sync', 'Start Date#v1sync', 'End Date#v1sync'];

export const EbfGeneratorFilter: FC<EbfGeneratorFilterProps> = ({
  filter,
  index,
  onRemoveFilter,
  control,
  watch,
  errors,
}) => {
  const filterField = watch(`Filters.${index}.FilterField`)
  const accountCustomFields = useSelector(selectCustomFieldNames);
  const customFieldOptions = React.useMemo(() => {
    const options: IOptions[] = [
      { label: 'Job Classification', value: 'Job Classification' },
      { label: 'Role', value: 'Role' },
      { label: 'Account ID', value: 'Account ID' },
    ];
    if (!accountCustomFields.length) {
      return options;
    }
    accountCustomFields.map((cf) => {
      const validateCustomField = validateCustomFields.find((f) => f.toLowerCase() === cf.toLowerCase());
      if (validateCustomField) {
        options.push({ label: cf.split('#')[0].trim(), value: cf });
      }
    });
    return options;
  }, [accountCustomFields]);

  const renderFilterValueSection = (): ReactElement => {
    if (filterField.toLowerCase() === 'start date#v1sync' || filterField.toLowerCase() === 'end date#v1sync') {
      return (
        <>
          <Col xs="3" md="3" key={`FilterStartDate.${filter.Id}`}>
            <Form.Group>
              <RawDateInput
                id={`Filters.${index}.FilterStartDate`}
                name={`Filters.${index}.FilterStartDate`}
                label="On or After"
                control={control}
                className="col"
                useSingleMonth
                required
                errors={errors && errors?.Filters?.[index]?.FilterStartDate?.message}
              />
            </Form.Group>
          </Col>
          <Col xs="3" md="3" key={`FilterEndDate.${filter.Id}`}>
            <Form.Group>
              <RawDateInput
                id={`Filters.${index}.FilterEndDate`}
                name={`Filters.${index}.FilterEndDate`}
                label="Before"
                control={control}
                className="col"
                useSingleMonth
                required
                errors={errors && errors?.Filters?.[index]?.FilterEndDate?.message}
              />
            </Form.Group>
          </Col>
        </>
      )
    }
    return (
      <Col xs="4" md="4" key={`FilterValue.${filter.Id}`}>
        <Form.Group>
          <Input
            id={`Filters.${index}.FilterValue`}
            name={`Filters.${index}.FilterValue`}
            type="text"
            label="Values"
            placeholder="Enter a comma seperated list of values "
            defaultValue={filter.FilterValue}
            control={control}
            maxLength={200}
            required
          />
        </Form.Group>
      </Col>
    );
  }

  return (
    <>
      {(index > 0) && (
        <Row>
          <Col xs="3" md="3" key={`Filters.and.${filter.Id}`} className="filter-col-small">
            AND
          </Col>
        </Row>
      )}
      <Row className="filter-row-children">
        <Col xs="1" md="1" key={`Filters.close.${filter.Id}`} className="filter-col-small">
          <FontAwesomeIcon icon="trash-alt" color="#FF7052" style={{ cursor: 'pointer' }} size="1x" onClick={() => onRemoveFilter(filter.Id)} />
        </Col>
        <Col xs="3" md="3" key={`FilterType.${filter.Id}`} className="without-padding-left">
          <Form.Group>
            <Input
              id={`Filters.${index}.Id`}
              name={`Filters.${index}.Id`}
              type="hidden"
              value={filter.Id}
              defaultValue={filter.Id}
              control={control}
              style={{ display: 'none' }}
            />
            <Select
              id={`Filters.${index}.FilterType`}
              name={`Filters.${index}.FilterType`}
              label="Filter"
              required
              control={control}
              options={filterTypeOptions}
              defaultValue="Include"
            />
          </Form.Group>
        </Col>
        <Col xs="1" md="1" key={`Filters.if.${filter.Id}`} className="filter-col-small without-padding">
          IF
        </Col>
        <Col xs="3" md="3" key={`FilterField.${filter.Id}`}>
          <Form.Group>
            <Select
              id={`Filters.${index}.FilterField`}
              name={`Filters.${index}.FilterField`}
              label="Profile Field"
              required
              control={control}
              options={customFieldOptions}
              defaultValue="Job Classification"
            />
          </Form.Group>
        </Col>
        <Col xs="1" md="1" key={`Filters.isin.${filter.Id}`} className="filter-col without-padding">
          IS IN
        </Col>
        {renderFilterValueSection()}
      </Row>
    </>
  );
};
