import React, { ReactElement, useMemo, useState } from 'react';
import clsx from 'clsx';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Dropdown from 'react-bootstrap/Dropdown';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import uuidv4 from 'uuid/v4';
import { IOptions } from './Select';

const questionCircle = <FontAwesomeIcon icon="question-circle" size="1x" />

export interface ISelectToggleProps {
  className?: string
  errors?: string
  onClick?: React.MouseEventHandler
  onBlur?: React.FocusEventHandler
  style?: React.CSSProperties
}

export const SelectToggle = React.forwardRef<HTMLButtonElement, ISelectToggleProps>(
  ({
    children, onClick, className, errors, ...props
  }, ref) => (
    <button
      type="button"
      className={clsx('form-control', errors && 'invalid', className)}
      onClick={onClick}
      ref={ref}
      {...props}
    >
      {children}
    </button>
  ),
);

export interface IMultiSelectProps {
  id: string
  name: string
  value?: string[]
  label?: string
  options: IOptions[]
  required?: boolean
  className?: string
  placeholder?: string
  defaultValue?: string[]
  errors?: string
  onChange?: (event) => void
  onBlur?: React.FocusEventHandler
  disabled?: boolean
  style?: React.CSSProperties
  readOnly?: boolean
  customTooltipIcon?: JSX.Element
  tooltip?: string
}

function MultiSelectInput({
  name, label, placeholder = '', required, value, defaultValue, customTooltipIcon, tooltip,
  options, errors, onChange, className = '', readOnly, ...props
}: IMultiSelectProps): ReactElement {
  const [isOpen, setIsOpen] = useState(false);
  const allOptions = useMemo(
    () => options,
    [options],
  );
  const { id } = props;
  const selectedValues = value || defaultValue || [];
  const selectedLabels = allOptions
    .filter((option) => selectedValues.includes(option.value))
    .map((option) => option.label);

  const tooltipIcon = customTooltipIcon ?? questionCircle;
  return (
    <div className={clsx('form-group SelectInput', className)}>
      {label && (
        <label htmlFor={id}>
          {label}
          &nbsp;
          {tooltip && (
            <OverlayTrigger
              placement="top"
              overlay={(
                <Tooltip id={`tooltip-${uuidv4()}`}>
                  {tooltip}
                </Tooltip>
              )}
            >
              {tooltipIcon}
            </OverlayTrigger>
          )}
          {!tooltip && customTooltipIcon}
        </label>
      )}
      <div style={{ position: 'relative' }}>
        {required ? <span className="required-asterisk">*</span> : null}
      </div>
      <Dropdown
        show={isOpen}
        onToggle={(toggled, evt, metadata) => {
          // ignore select events so that dropdown doesn't close on each selection
          if (metadata.source !== 'select') {
            setIsOpen(toggled);
          }
        }}
      >
        <Dropdown.Toggle
          id={id}
          errors={errors}
          as={SelectToggle}
          onClick={() => setIsOpen(!isOpen)}
          {...props}
        >
          <span className="selected-option">
            {selectedLabels.length > 0 ? selectedLabels.join(', ') : placeholder}
          </span>
          <FontAwesomeIcon icon="chevron-down" className="SelectInput__arrow" />
        </Dropdown.Toggle>
        <Dropdown.Menu popperConfig={{ modifiers: [{ name: 'offset', options: { offset: [0, -2] } }] }}>
          {allOptions.map((item) => (
            <Dropdown.Item
              key={item.value}
              onClick={() => {
                const newValues = selectedValues.includes(item.value)
                  ? selectedValues.filter((val) => val !== item.value)
                  : [...selectedValues, item.value];

                onChange(newValues);
              }}
              disabled={item.disabled}
            >
              <input
                style={{ pointerEvents: 'none' }}
                className="mr-2"
                type="checkbox"
                checked={selectedValues.includes(item.value)}
                readOnly
              />
              <span className="menu-option">{item.label}</span>
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      </Dropdown>

    </div>
  );
}

export default MultiSelectInput;
