import React, { ReactElement } from 'react';

import { Controller } from 'react-hook-form';

import MultiSelectInput, { IMultiSelectProps } from 'components/Inputs/MultiSelectInput';
import { IFormControlProps } from './IFormControlProps';

interface IProps extends Omit<IFormControlProps, 'defaultValue'>, IMultiSelectProps {}

function MultiSelect({
  name, defaultValue, control, rules, ...props
}: IProps): ReactElement {
  return (
    <Controller
      name={name}
      defaultValue={defaultValue}
      control={control}
      rules={rules}
      render={(renderProps) => (
        <MultiSelectInput {...renderProps} {...props} />
      )}
    />
  );
}

export default MultiSelect;
