import React, {
  ReactElement,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { Helmet } from 'react-helmet';
import Input from 'components/Inputs/Input';
import {
  Col, Container, ListGroup, Row,
} from 'react-bootstrap';
import useSWR from 'swr';
import { getUserState, getAllAccounts as selectAllAccounts, updateAccountIntegrationsSuccess } from 'reducers/UserInfo';
import { useDispatch, useSelector } from 'react-redux';
import * as UserInfoAPI from 'api/UserInfoAPI';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { toast } from 'react-toastify';
import { Prompt } from 'react-router-dom';
import { getB64StringFromFile } from 'utils/file';
import ColorInput from 'components/Inputs/ColorInput';
import Select from 'react-select';
import Checkbox from 'components/Inputs/Checkbox';
import useSWRMutation from 'swr/mutation';
import _orderBy from 'lodash/orderBy';
import MultiSelectInput from 'components/Inputs/MultiSelectInput';
import { IOptions } from 'components/Inputs/Select';

const domainPattern = /^[A-Za-z0-9.-]+\.[A-Za-z]{2,4}/i;
const contentRegex = /^(?=.*[A-Za-z0-9])[A-Za-z0-9 .,!?;:'"()\[\]{}_-]+$/;
const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/i;
const emojiRegex = /[\uD800-\uDBFF][\uDC00-\uDFFF]/g;
const defaultParentAccountOption = { value: '0', label: 'No Parent Account Selected' };
const defaultBackgroundColor = '#e0e0e0';
const defaultBrandColor = '#0F4CDB';

function AccountSettingsPage(): ReactElement {
  const dispatch = useDispatch();
  const userState = useSelector(getUserState);
  const allAccounts = useSelector(selectAllAccounts);
  const accountId = userState.userInfo?.AccountDetail?.AccountID ?? '';

  const { data: dbPermittedDomains, mutate, isLoading } = useSWR(
    ['permitted-domains', accountId],
    () => UserInfoAPI.getAccountPermittedDomain(),
    {
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    },
  );
  const messageObj = {
    title: 'Account Setting Changes',
    messageText: 'You have unsaved changes, are you sure you want to leave?',
  };

  const [domainName, setDomainName] = useState('');
  const [domainList, setDomainList] = useState<string[]>([]);

  const sortedDbDomains = useMemo(() => dbPermittedDomains?.sort((a, b) => a.localeCompare(b)), [dbPermittedDomains]);
  const sortedNewDomains = useMemo(() => domainList?.sort((a, b) => a.localeCompare(b)), [domainList]);
  // only show the save button when the new domain list is different from the orignial one in database.
  const saveEnabled = useMemo(
    () => JSON.stringify(sortedDbDomains) !== JSON.stringify(sortedNewDomains), [sortedDbDomains, sortedNewDomains]);
  const [isSaving, setIsSaving] = useState<boolean>();
  const saveDisabled = !saveEnabled || isSaving;

  const accountsOptions = React.useMemo(() => {
    if (!allAccounts.length) {
      return [];
    }
    const options = [defaultParentAccountOption];
    allAccounts
      .map((account) => (options.push({
        value: `${account.Id}`,
        label: account.Name,
      })));
    return options;
  }, [allAccounts]);

  useEffect(() => {
    setDomainList(dbPermittedDomains);
  }, [dbPermittedDomains]);

  const addToDomainList = (domain): boolean => {
    if (domain.trim() === '') {
      return false;
    }

    if (domain.length > 253) {
      toast.error('The length of domain name should not longer than 253 characters.');
      return false;
    }

    if (!domainPattern.test(domain)) {
      toast.error(`'${domain}' is not a valid domain name.`);
      return false;
    }

    // deduplicate case insensetivily
    if (!domainList.some((d) => d.toLowerCase() == domain.toLowerCase())) {
      setDomainList(domainList.concat(domain));
    } else {
      toast.error(`'${domain}' has already been added to the list.`);
    }
    return true;
  };

  const onRemoveDomain = (domain) => {
    if (domainList.includes(domain)) {
      const newDomains = domainList.filter((d) => d !== domain);
      setDomainList(newDomains);
    }
  }

  const onPermittedDomainSave = async () => {
    setIsSaving(true);
    try {
      const savedDomains = await UserInfoAPI.setAccountPermittedDomain(domainList);
      setDomainList(savedDomains);
      mutate(savedDomains, {
        populateCache: true, // populate cache with saved domains
        revalidate: false, // do not refetch
      });
      toast.success('Permitted domain successfully saved.');
    } catch (error) {
      toast.error(error.response?.data?.Message ?? 'Something went wrong');
    } finally {
      setIsSaving(false);
    }
  }

  const renderDomainList = (): ReactElement => {
    if (isLoading || bfAccountCfgLoading || restrictedBroadcastFormsLoading) {
      return (
        <ListGroup>
          <ListGroup.Item>
            <label className="domain-name">Loading...</label>
          </ListGroup.Item>
        </ListGroup>
      )
    }

    return (
      <ListGroup>
        {
          domainList && domainList.map((domain) => (
            <ListGroup.Item
              key={domain}
            >
              <button
                className="px-1 remove-recipient-btn account-list"
                type="button"
                onClick={() => {
                  onRemoveDomain(domain);
                }}
              >
                <FontAwesomeIcon
                  icon="times"
                  size="sm"
                />
              </button>
              <label className="domain-name" title={domain}>{domain}</label>
            </ListGroup.Item>
          ),
          )
        }
      </ListGroup>
    )
  }

  const renderSavedPermittedDomains = (): ReactElement => {
    if (!isLoading && (!dbPermittedDomains || dbPermittedDomains.length === 0)) {
      return (
        <div className="empty-domain">No Permitted Domains have been set.</div>
      )
    }
    return (
      <ListGroup>
        {
          dbPermittedDomains && dbPermittedDomains.map((domain) => (
            <ListGroup.Item
              key={domain}
            >
              <label className="domain-name" title={domain}>{domain}</label>
            </ListGroup.Item>
          ),
          )
        }
      </ListGroup>
    )
  }

  const [senderName, setSenderName] = useState('');
  const [senderEmail, setSenderEmail] = useState('');
  const [replyEmail, setReplyEmail] = useState('');
  const [brandColor, setBrandColor] = useState(defaultBrandColor);
  const [parentAccount, setParentAccount] = useState(defaultParentAccountOption);
  const [enabled, setEnabled] = useState(false);
  const [preventAnonymousResponses, setPreventAnonymousResponses] = useState(false);

  const [backgroundColor, setBackgroundColor] = useState(defaultBackgroundColor);
  // for displaying in image component
  // for uploading to backend
  const [brandLogoContent, setBrandLogoContent] = useState(null);

  const [notificationRecipient, setNotificationRecipient] = useState('');
  const [notificationRecipients, setNotificationRecipients] = useState<string[]>([]);

  const [restrictedBroadcastForms, setRestrictedBroadcastForms] = useState<IOptions[]>([]);
  const [restrictedBroadcastFormAccess, setRestrictedBroadcastFormAccess] = useState<string[]>([]);

  const configModel = useMemo(() => {
    const config = {
      SenderDisplayName: senderName,
      SenderEmailAddress: senderEmail,
      ReplyEmailAddress: replyEmail,
      BrandColor: brandColor,
      BrandLogoContent: brandLogoContent,
      ParentAccountId: +(parentAccount?.value ?? 0),
      Enabled: enabled,
      BackgroundColor: backgroundColor,
      NotificationRecipients: notificationRecipients,
      RestrictedBroadcastFormAccess: restrictedBroadcastFormAccess,
      PreventAnonymousResponses: preventAnonymousResponses,
    };
    return JSON.stringify(config);
  }, [senderName, senderEmail, replyEmail, brandColor, brandLogoContent, parentAccount, preventAnonymousResponses, enabled, backgroundColor, notificationRecipients, restrictedBroadcastFormAccess]);
  const { data: bfAccountConfigs, mutate: bfAccountCfgMutate, isLoading: bfAccountCfgLoading } = useSWR(
    ['broadcastforms-account-cfg', accountId],
    () => UserInfoAPI.getBroadcastFormsAccountConfig(),
    {
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    },
  );
  const { data: bfRestrictedBroadcastForms, isLoading: restrictedBroadcastFormsLoading } = useSWR(
    ['restricted-broadcastforms', accountId],
    () => UserInfoAPI.getRestrictedBroadcastForms(),
    {
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    },
  );
  const saveConfigEnabled = useMemo(
    () => JSON.stringify(bfAccountConfigs) !== configModel, [bfAccountConfigs, configModel]);
  const [isConfigSaving, setIsConfigSaving] = useState<boolean>();
  const saveConfigDisabled = !saveConfigEnabled || isConfigSaving;

  const setImageContent = (content) => {
    const img = document.getElementById('imgBrandLogo');
    if (content) {
      img?.setAttribute('src', content);
      img?.removeAttribute('hidden');
    } else {
      img.removeAttribute('src');
      img.setAttribute('hidden', 'hidden');
    }
  }

  const onUploadLogo = () => {
    const fileUploader = document.getElementById('fileUploader');
    fileUploader.click();
  }
  const onRemoveLogo = () => {
    setImageContent('');
    setBrandLogoContent(null);
  }

  useEffect(() => {
    if (bfAccountConfigs) {
      setSenderName(bfAccountConfigs.SenderDisplayName);
      setReplyEmail(bfAccountConfigs.ReplyEmailAddress);
      setSenderEmail(bfAccountConfigs.SenderEmailAddress);
      setBrandColor(bfAccountConfigs.BrandColor);
      setBackgroundColor(bfAccountConfigs.BackgroundColor ?? defaultBackgroundColor);
      setNotificationRecipients(bfAccountConfigs.NotificationRecipients);
      const selectedAccount = accountsOptions.find((account) => {
        if (+account.value === bfAccountConfigs.ParentAccountId) return account;
        return null;
      });
      setParentAccount(selectedAccount);
      if (bfAccountConfigs.BrandLogoContent) {
        setBrandLogoContent(bfAccountConfigs.BrandLogoContent);
        setImageContent(bfAccountConfigs.BrandLogoContent);
      }
      setEnabled(bfAccountConfigs.Enabled);

      const restrictedBfs :IOptions[] = []
      bfRestrictedBroadcastForms?.map((d) => {
        restrictedBfs.push({ label: d, value: d });
      });
      setRestrictedBroadcastForms(restrictedBfs);
      setRestrictedBroadcastFormAccess(bfAccountConfigs.RestrictedBroadcastFormAccess);
      setPreventAnonymousResponses(bfAccountConfigs.PreventAnonymousResponses)
    }
  }, [accountsOptions, bfAccountConfigs, bfRestrictedBroadcastForms]);

  const getBrandLogoBackgroundColor = () => {
    if (backgroundColor.length === 7) {
      return backgroundColor;
    }
    return '#000000';
  }

  const addToNotificationRecipients = (recipient): boolean => {
    if (recipient.trim() === '') {
      return false;
    }

    if (recipient.length > 255) {
      toast.error('The length of notification recipient should not longer than 255 characters.');
      return false;
    }

    if (!emailRegex.test(recipient) || emojiRegex.test(integrationPartnerSenior)) {
      toast.error(`'${recipient}' is not a valid email address.`);
      return false;
    }

    // deduplicate case insensetivily
    if (!notificationRecipients?.some((d) => d.toLowerCase() == recipient.toLowerCase())) {
      if (notificationRecipients) {
        setNotificationRecipients([recipient, ...notificationRecipients]);
      } else {
        setNotificationRecipients([recipient]);
      }
    } else {
      toast.error(`'${recipient}' has already been added to the list.`);
    }
    return true;
  };

  const onRemoveNotificationRecipients = (recipient) => {
    if (notificationRecipients.includes(recipient)) {
      const newRecipient = notificationRecipients.filter((d) => d !== recipient);
      setNotificationRecipients(newRecipient);
    }
  }

  const renderNotificationRecipients = (): ReactElement => {
    if (isLoading || bfAccountCfgLoading || restrictedBroadcastFormsLoading) {
      return (
        <ListGroup>
          <ListGroup.Item>
            <label className="domain-name">Loading...</label>
          </ListGroup.Item>
        </ListGroup>
      )
    }

    return (
      <ListGroup>
        {
          notificationRecipients && notificationRecipients.map((recipient) => (
            <ListGroup.Item
              key={recipient}
            >
              <button
                className="px-1 remove-recipient-btn account-list"
                type="button"
                onClick={() => {
                  onRemoveNotificationRecipients(recipient);
                }}
              >
                <FontAwesomeIcon
                  icon="times"
                  size="sm"
                />
              </button>
              <label className="domain-name" title={recipient}>{recipient}</label>
            </ListGroup.Item>
          ),
          )
        }
      </ListGroup>
    )
  }

  const onBroadcastFormsAccountConfigSave = async () => {
    setIsConfigSaving(true);
    try {
      const data = {
        senderName,
        senderEmail,
        replyEmail,
        brandColor: brandColor ?? '#0F4CDB',
        brandLogo: brandLogoContent,
        parentAccountId: +parentAccount.value,
        enabled,
        preventAnonymousResponses,
        backgroundColor: backgroundColor ?? '#e0e0e0',
        notificationRecipients,
        restrictedBroadcastFormAccess,
      }
      if (!data.brandColor) {
        toast.error('The brand color is required.');
        setIsConfigSaving(false);
        return;
      }
      if (!data.backgroundColor) {
        toast.error('The background color is required.');
        setIsConfigSaving(false);
        return;
      }
      await UserInfoAPI.saveBroadcastFormsAccountConfig(data);
      bfAccountCfgMutate();
      toast.success('Broadcast Forms Account Configuration successfully saved.');
    } catch (error) {
      toast.error(error.response?.data?.Message ?? 'Something went wrong');
    } finally {
      setIsConfigSaving(false);
    }
  }

  // AccountIntegrations
  const [integrationPartnerSenior, setIntegrationPartnerSenior] = useState('');
  const [integrationPartnerStaff, setIntegrationPartnerStaff] = useState('');
  const [integrationPartnerFamily, setIntegrationPartnerFamily] = useState('');
  const { data: accountIntegrations, mutate: accountIntegrationsMutate, isLoading: accountIntegrationsLoading } = useSWR(
    ['account-integrations', accountId],
    () => UserInfoAPI.getAccountIntegrations(),
    {
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    },
  );
  const integrationModel = useMemo(() => {
    const items = [];
    if (integrationPartnerSenior) {
      items.push({ AccountId: accountId, UserType: 'Seniors', IntegrationPartner: integrationPartnerSenior });
    }
    if (integrationPartnerStaff) {
      items.push({ AccountId: accountId, UserType: 'Staff', IntegrationPartner: integrationPartnerStaff });
    }
    if (integrationPartnerFamily) {
      items.push({ AccountId: accountId, UserType: 'Family', IntegrationPartner: integrationPartnerFamily });
    }
    return JSON.stringify(_orderBy(items, ['UserType'], ['asc']));
  }, [integrationPartnerSenior, integrationPartnerStaff, integrationPartnerFamily, accountId]);
  const saveIntegrationEnabled = useMemo(
    () => JSON.stringify(_orderBy(accountIntegrations, ['UserType'], ['asc'])) !== integrationModel, [accountIntegrations, integrationModel]);
  const [isIntegrationSaving, setIsIntegrationSaving] = useState<boolean>();
  const saveIntegrationDisabled = !saveIntegrationEnabled || isIntegrationSaving;

  useEffect(() => {
    if (accountIntegrations) {
      accountIntegrations.forEach((item) => {
        if (item.UserType.toLowerCase() === 'seniors') {
          setIntegrationPartnerSenior(item.IntegrationPartner);
        } else if (item.UserType.toLowerCase() === 'family') {
          setIntegrationPartnerFamily(item.IntegrationPartner);
        } else if (item.UserType.toLowerCase() === 'staff') {
          setIntegrationPartnerStaff(item.IntegrationPartner);
        }
      })
    }
  }, [accountIntegrations, accountIntegrationsLoading]);

  const updateAccountIntegrations = async () => {
    const items = [];
    if (integrationPartnerSenior?.trim()) {
      items.push({ AccountId: accountId, UserType: 'Seniors', IntegrationPartner: integrationPartnerSenior?.trim() });
    }
    if (integrationPartnerStaff?.trim()) {
      items.push({ AccountId: accountId, UserType: 'Staff', IntegrationPartner: integrationPartnerStaff?.trim() });
    }
    if (integrationPartnerFamily?.trim()) {
      items.push({ AccountId: accountId, UserType: 'Family', IntegrationPartner: integrationPartnerFamily?.trim() });
    }
    return UserInfoAPI.saveAccountIntegrations(items);
  }

  const { trigger } = useSWRMutation('updateaccountintegrations', updateAccountIntegrations, {
    revalidate: true,
    populateCache: false,
    onSuccess: (data) => {
      // update the account integrations in reducer, no need refresh page
      dispatch(updateAccountIntegrationsSuccess(data));
      accountIntegrationsMutate();
      toast.success('Account Integration Banners successfully saved.');
      setIsIntegrationSaving(false);
    },
    onError: (err) => {
      toast.error(err.response?.data?.Message ?? 'Something went wrong');
      setIsIntegrationSaving(false);
    },
  });

  const onIntegrationPartnerSave = async () => {
    if (integrationPartnerSenior && (!contentRegex.test(integrationPartnerSenior) || emojiRegex.test(integrationPartnerSenior))) {
      toast.error('Integration Partner Senior is invalid.');
      return;
    }
    if (integrationPartnerStaff && (!contentRegex.test(integrationPartnerStaff) || emojiRegex.test(integrationPartnerStaff))) {
      toast.error('Integration Partner Staff is invalid.');
      return;
    }
    if (integrationPartnerFamily && (!contentRegex.test(integrationPartnerFamily) || emojiRegex.test(integrationPartnerFamily))) {
      toast.error('Integration Partner Family is invalid.');
      return;
    }
    setIsIntegrationSaving(true);
    trigger();
  }

  const accountSettingSaveEnabled = saveEnabled || saveConfigEnabled || saveIntegrationEnabled;

  return (
    <>
      <Prompt
        when={accountSettingSaveEnabled}
        message={JSON.stringify(messageObj)}
      />
      <Helmet>
        <title>Account Settings</title>
      </Helmet>
      <div className="container">
        <h3>Account Permitted Domains</h3>
        <p>
          Domains that have been DKIM registered have restricted usage. Add domains below to authorize this
          account to use a DKIM registered domain
        </p>
        <form>
          <Container fluid>
            <Row>
              <Col xs={5}>
                <Input
                  className="mb-0"
                  type="text"
                  label="Add authorized domain (enter to submit)"
                  placeholder="Email Domain"
                  value={domainName}
                  onKeyDown={(evt) => {
                    if (evt.key === 'Enter') {
                      evt.preventDefault();
                      addToDomainList(domainName);
                      setDomainName('');
                    }
                  }}
                  onChange={(e) => {
                    setDomainName(e.target.value);
                  }}
                  style={{ maxWidth: '100%' }}
                />
              </Col>
              <Col xs={2}>
                <button
                  type="button"
                  className="btn btn-primary square mb-0 "
                  style={{ marginTop: 25 }}
                  onClick={() => {
                    if (addToDomainList(domainName)) {
                      setDomainName('');
                    }
                  }}
                >
                  Add
                </button>
              </Col>
            </Row>
            <Row>
              <Col xs={7} />
              <Col>
                <label
                  className="saved-caption"
                >
                  Currently saved permitted domains
                </label>
              </Col>
            </Row>
            <Row>
              <Col xs={5}>
                { renderDomainList() }
              </Col>
              <Col xs={2} />
              <Col>
                { renderSavedPermittedDomains() }
              </Col>
            </Row>
            <br />
            <br />
            <Row>
              <Col xs={5}>
                <button
                  type="button"
                  className="btn btn-primary square mb-0"
                  onClick={onPermittedDomainSave}
                  disabled={saveDisabled}
                >
                  Save
                </button>
              </Col>
            </Row>
          </Container>
        </form>
      </div>
      <hr className="broadcast-container" />
      <div className="container">
        <h3>Account Integration Banners</h3>
        <p>
          Set the integration banners displayed on the Profiles page for each user type. Leave empty if no integration banner should be displayed.
        </p>
        <form>
          <Container fluid>
            <Row>
              <Col xs={6} className="form-group">
                <Input
                  className="mb-0"
                  type="text"
                  name="IntegrationPartnerSenior"
                  label="Senior"
                  maxLength={30}
                  value={integrationPartnerSenior}
                  placeholder="Optional: Integration Partner's name"
                  onChange={(e) => {
                    setIntegrationPartnerSenior(e.target.value);
                  }}
                  style={{ maxWidth: '100%' }}
                />
              </Col>
            </Row>
            <Row>
              <Col xs={6} className="form-group">
                <Input
                  className="mb-0"
                  type="text"
                  name="IntegrationPartnerStaff"
                  label="Staff"
                  maxLength={30}
                  value={integrationPartnerStaff}
                  placeholder="Optional: Integration Partner's name"
                  onChange={(e) => {
                    setIntegrationPartnerStaff(e.target.value);
                  }}
                  style={{ maxWidth: '100%' }}
                />
              </Col>
            </Row>
            <Row>
              <Col xs={6} className="form-group">
                <Input
                  className="mb-0"
                  type="text"
                  name="IntegrationPartnerFamily"
                  label="Family"
                  maxLength={30}
                  value={integrationPartnerFamily}
                  placeholder="Optional: Integration Partner's name"
                  onChange={(e) => {
                    setIntegrationPartnerFamily(e.target.value);
                  }}
                  style={{ maxWidth: '100%' }}
                />
              </Col>
            </Row>
            <br />
            <br />
            <Row>
              <Col xs={5}>
                <button
                  type="button"
                  className="btn btn-primary square mb-0"
                  onClick={onIntegrationPartnerSave}
                  disabled={saveIntegrationDisabled}
                >
                  Save
                </button>
              </Col>
            </Row>
          </Container>
        </form>
      </div>
      <hr className="broadcast-container" />
      <div className="container">
        <h3>Broadcast Forms Account Configuration</h3>
        <p>
          Enable feedback 360 module, configure the appearance, branding and default email sender (enterprise surveys)
          for any broadcast form content sent from this account.
        </p>
        <form>
          <Container fluid>
            <Row style={{ marginTop: 5 }}>
              <Col xs={10}>
                <Checkbox
                  id="Enabled"
                  name="Enabled"
                  label="Enable Feedback360 for this account"
                  checked={enabled}
                  onChange={(e) => {
                    setEnabled(e.target.checked);
                  }}
                />
              </Col>
            </Row>

            <Row>
              <Col xs={10}>
                <Checkbox
                  id="PreventAnonymousResponses"
                  name="PreventAnonymousResponses"
                  label="Disable anonymous responses"
                  customIconTooltip={`Check this box if you want to disable a survey 
                    recipient's ability to respond anonymously to surveys in this account.`}
                  customIcon={(
                    <FontAwesomeIcon icon="question-circle" size="1x" />
                  )}
                  checked={preventAnonymousResponses}
                  onChange={(e) => {
                    setPreventAnonymousResponses(e.target.checked);
                  }}
                />
              </Col>
            </Row>
            <br />
            <Row>
              <Col xs={4}>
                <p><b>Brand Color</b></p>
                <ColorInput
                  type="text"
                  value={brandColor ?? defaultBrandColor}
                  onChange={(e : any) => {
                    let hex = e.target.value;
                    if (hex.length > 7) {
                      e.preventDefault();
                      return;
                    }
                    if (hex.length === 6) {
                      hex = `#${e.target.value?.replace(/^#?/, '')}`;
                    }

                    setBrandColor(hex);
                  }}
                />
              </Col>
              <Col xs={4}>
                <p><b>Background Color</b></p>
                <ColorInput
                  type="text"
                  value={backgroundColor ?? defaultBackgroundColor}
                  onChange={(e : any) => {
                    let hex = e.target.value;
                    if (hex.length > 7) {
                      e.preventDefault();
                      return;
                    }
                    if (hex.length === 6) {
                      hex = `#${e.target.value?.replace(/^#?/, '')}`;
                    }

                    setBackgroundColor(hex);
                  }}
                />
              </Col>
            </Row>
            <br />
            <p><b>Brand Logo</b></p>
            <Row>
              <Col xs={3}>
                <div
                  className="broadcast-cell"
                  style={{
                    backgroundColor: getBrandLogoBackgroundColor() ?? defaultBackgroundColor,
                  }}
                >
                  <img
                    alt="Brands logo"
                    id="imgBrandLogo"
                    hidden
                  />
                </div>
              </Col>
            </Row>
            <Row style={{ marginTop: 5 }}>
              <Col xs={10}>
                <button
                  className="broadcast-button-upload"
                  type="button"
                  onClick={() => { onUploadLogo() }}
                >
                  <span style={{ color: '#595b5c' }}>Replace Logo</span>
                </button>
                <button
                  type="button"
                  className="broadcast-button-remove"
                  onClick={() => { onRemoveLogo() }}
                >
                  <span style={{ color: '#a60202' }}>Remove Logo</span>
                </button>
              </Col>
            </Row>
            <Input
              type="file"
              id="fileUploader"
              accept="image/png, image/jpeg, image/svg+xml"
              onChange={async (e) => {
                const result = await getB64StringFromFile(e.target.files[0]);
                setImageContent(result);
                setBrandLogoContent(result);
              }}
              hidden
            />
            <br />
            <p><b>Enterprise Survey Parent Account</b></p>
            <p>
              Optional: To enable multi-account surveys, select the parent account for this account.
              The parent account will then be able to send multi-account surveys to this account.
              Select &quot;No Parent Account Selected&quot; if this account should not receive enterprise surveys.
            </p>
            <Row>
              <Col xs={12} md={6}>
                <Select
                  id="ParentAccount"
                  name="ParentAccount"
                  value={parentAccount}
                  label="Enterprise Survey Parent Account"
                  placeholder="No Parent Account Selected"
                  className="selectInputPaddingRight"
                  options={accountsOptions}
                  onChange={(value) => {
                    setParentAccount(value);
                  }}
                />
              </Col>
            </Row>
            <br />
            <p><b>Enterprise Survey Email Sender</b></p>
            <p>
              This is the email sender information used by this account when sending out surveys created
              by this accounts Enterprise survey parent account. If an enterprise survey parent account is
              specified, this value MUST be set.
            </p>
            <Row>
              <Col xs={3}>
                <Input
                  className="mb-0"
                  type="text"
                  label="Sender's Display Name"
                  name="SenderDisplayName"
                  maxLength={255}
                  value={senderName}
                  onChange={(e) => {
                    setSenderName(e.target.value);
                  }}
                  style={{ maxWidth: '100%' }}
                />
              </Col>
              <Col xs={3}>
                <Input
                  className="mb-0"
                  type="text"
                  name="SenderEmailAddress"
                  label="Sender's Email Address"
                  maxLength={64}
                  value={senderEmail}
                  onChange={(e) => {
                    setSenderEmail(e.target.value);
                  }}
                  style={{ maxWidth: '100%' }}
                />
              </Col>
              <Col xs={3}>
                <Input
                  className="mb-0"
                  type="text"
                  name="ReplyEmailAddress"
                  label="Reply-To Email Address"
                  maxLength={64}
                  value={replyEmail}
                  onChange={(e) => {
                    setReplyEmail(e.target.value);
                  }}
                  style={{ maxWidth: '100%' }}
                />
              </Col>
            </Row>
            <br />
            <p><b>Survey Notifications</b></p>
            <p>
              Add one or more emails to receive notifications whenever a survey is submitted.
            </p>
            <Row>
              <Col xs={5}>
                <Input
                  className="mb-0"
                  type="text"
                  placeholder="Notification Recipient"
                  value={notificationRecipient}
                  onKeyDown={(evt) => {
                    if (evt.key === 'Enter') {
                      evt.preventDefault();
                      addToNotificationRecipients(notificationRecipient);
                      setNotificationRecipient('');
                    }
                  }}
                  onChange={(e) => {
                    setNotificationRecipient(e.target.value);
                  }}
                  style={{ maxWidth: '100%' }}
                />
              </Col>
              <Col xs={2}>
                <button
                  type="button"
                  className="btn btn-primary square mb-0 "
                  onClick={() => {
                    if (addToNotificationRecipients(notificationRecipient)) {
                      setNotificationRecipient('');
                    }
                  }}
                >
                  Add
                </button>
              </Col>
            </Row>
            <br />
            <Row>
              <Col xs={5}>
                { renderNotificationRecipients() }
              </Col>
            </Row>
            <br />
            <p><b>Additional Survey Types</b></p>
            <Row>
              <Col xs={12} md={6}>
                <MultiSelectInput
                  label="Additional Survey Types"
                  id="RestrictedSurveys"
                  name="RestrictedSurveys"
                  placeholder="Select Additional Survey Types"
                  tooltip="Select any additional survey types that this account should be able to send. Enterprise survey accounts can send these to subaccounts even if the subaccount has not been granted access to use this survey type itself."
                  options={restrictedBroadcastForms}
                  value={restrictedBroadcastFormAccess?.map((x) => x) ?? []}
                  onChange={(v) => setRestrictedBroadcastFormAccess(v)}
                />
              </Col>
            </Row>
            <br />
            <Row>
              <Col xs={5}>
                <button
                  type="button"
                  className="btn btn-primary square mb-0"
                  disabled={saveConfigDisabled}
                  onClick={onBroadcastFormsAccountConfigSave}
                >
                  Save
                </button>
              </Col>
            </Row>
          </Container>
        </form>
      </div>
    </>
  )
}

export default AccountSettingsPage;
