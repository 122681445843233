import React, { ReactElement } from 'react';
import { Helmet } from 'react-helmet';
import { Redirect, useHistory } from 'react-router-dom';
import { ReactComponent as Plus } from 'styles/images/plus.svg';
import { useHasRoles } from 'hooks/useHasRoles';
import { TenantConfigTable } from './TenantConfigTable';

const V1SyncPage = (): ReactElement => {
  const history = useHistory();
  const [isAdmin, isSuper] = useHasRoles(['Admin', 'Super']);
  const hasAccessPermission = isAdmin || isSuper;

  if (!hasAccessPermission) {
    return <Redirect to="/" />;
  }

  return (
    <div className="broadcast-forms Templates">
      <Helmet>
        <title>V1 Sync Integration setup</title>
      </Helmet>
      <h3> V1 Sync Tenant Configurations</h3>
      <div className="broadcast-forms__header-description">
        This is an internal tool accessible to super users to work with the v1 sync.
      </div>
      <div className="header-bar broadcast-forms__header-bar mt-4">
        <button
          role="link"
          type="button"
          onClick={() => history.push('/v1sync/edit/new')}
          className="btn btn-lg btn-warning "
        >
          <Plus
            width="24"
            height="24"
            fill="currentColor"
            style={{ marginRight: '10px' }}
          />
          Add New Tenant Config
        </button>
      </div>
      <TenantConfigTable showActions />
    </div>
  );
};

export default V1SyncPage;
