import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import Compliance from 'pages/Administration/Compliance';
import Conference from 'pages/Administration/Conference';
import { Container } from 'react-bootstrap';

import { useHasRoles } from 'hooks/useHasRoles';
import { IFrame } from 'components/IframeComponent';
import { IFrameTabs } from 'components/IframeTabsComponent';
import AccushieldReportPage from 'pages/Reports/AccushieldReport';
import LoggedOutPage from 'pages/Errors/loggedout';
import CreateMfa from 'pages/Administration/CreateMfa';
import SmsInboxPage from 'pages/SmsInboxPage';
import BroadcastHistoryPage from 'pages/BroadcastHistoryPage';
import _capitalize from 'lodash/capitalize';
import { useUserTypeTranslation } from 'hooks/useUserTypeTranslation';
import { useSelector } from 'react-redux';
import { selectIconUsersOnly } from 'reducers/UserInfo';
import EnterpriseResidentSurveyReportPage from 'pages/Reports/EnterpriseResidentSurveyReport';
import EnterpriseStaffSurveyReportPage from 'pages/Reports/EnterpriseStaffSurveyReport';
import AppWrapper from '../components/AppWrapper';
import CalendarRoutes from './CalendarRoutes';
import ProfilesRoutes from './ProfilesRoutes';
import Error403Page from '../pages/Errors/403';
import Error404Page from '../pages/Errors/404';
import Error500Page from '../pages/Errors/500';
import LibraryContentRoutes from './LibraryContentRoutes';
import CalendarEventRoutes from './CalendarEventRoutes';
import GroupsRoutes from './GroupsRoutes';
import EventsRoutes from './EventsRoutes';
import TransactionalTemplateRoutes from './TransactionalTemplateRoutes'
import TemplateRoutes from './TemplateRoutes';
import Import from '../pages/Administration/Import';
import DischargedPatientsRoutes from './DischargedPatientsRoutes';
import RerouteAccount from '../pages/Administration/RerouteAccount';
import ReportPage from '../pages/Reports/Reports';
import DashboardRoutes from './DashboardRoutes';
import SurveyQuestionListRoutes from './SurveyQuestionListRoutes.tsx';
import AdministrationRoutes from './AdministrationRoutes';
import EnterpriseReportPage from '../pages/Reports/EnterpriseReport';
import EnterpriseTextReportPage from '../pages/Reports/EnterpriseTextReport';
import EnterpriseSurveysReportPage from '../pages/Reports/EnterpriseSurveysReport';
import InternalSettingRoutes from './InternalSettings';
import MarketingTemplateRoutes from './MarketingTemplateRoutes';
import BroadcastFormsRoutes from './BroadcastFormsRoutes';
import PbiRoutes from './PbiRoutes';
import SmsAutoReplyRoutes from './SmsAutoReplyRoutes';
import V1SyncRoutes from './V1SyncRoutes';

function LegacyUiRoutes() {
  const translateUserType = useUserTypeTranslation();
  const isIconUsersOnly = useSelector(selectIconUsersOnly);

  return (
    <Switch>
      <Route
        path="/legacy/virtualmeeting"
        render={() => (
          <IFrame url="/aspx/Conference/Conferences.aspx" />
        )}
      />
      <Route
        path="/legacy/multiaccountsend"
        render={() => (
          <IFrame url="/aspx/Administration/MultiAccountSendTool.aspx" />
        )}
      />
      <Route
        path="/legacy/appointmentreminders"
        render={() => (
          <IFrame url="/aspx/AppointmentReminder/AppointmentReminders.aspx" />
        )}
      />
      <Route
        path="/legacy/wellness"
        render={() => (
          <IFrame url="/aspx/Wellness/Index.aspx" />
        )}
      />
      <Route
        path="/legacy/attendance"
        render={() => (
          <IFrame url="/aspx/Activity/Activities.aspx" />
        )}
      />
      <Route
        path="/legacy/administration"
        render={() => (
          <IFrame url="/aspx/Administration/Administration.aspx" />
        )}
      />
      <Route
        path="/legacy/accountsettings"
        render={() => (
          <IFrameTabs params={[
            {
              tabName: 'User Management',
              scrolling: true,
              tabKey: 'UserManagement',
              url: '/aspx/Administration/UserManagement.aspx',
              visible: !isIconUsersOnly,
            },
            {
              tabName: 'Permission Management',
              tabKey: 'PermissionManagement',
              url: '/aspx/Administration/PermissionManagement.aspx',
              visible: !isIconUsersOnly,
            },
            {
              tabName: 'Account Management',
              tabKey: 'AccountManagement',
              url: '/aspx/Administration/AccountManagement.aspx',
              scrolling: true,
              visible: true,
            },
            {
              tabName: 'Custom Fields Management',
              tabKey: 'CustomFieldsManagement',
              url: '/aspx/Administration/CustomFieldsManagement.aspx',
              visible: true,
            },
          ]}
          />
        )}
      />
      <Route
        path="/legacy/usagereports"
        render={() => (
          <IFrameTabs params={[
            {
              tabName: 'Message Report',
              scrolling: true,
              tabKey: 'MessageReport',
              url: '/aspx/Administration/MessageReport.aspx',
              visible: true,
            },
            {
              tabName: 'Account System Usage Report',
              scrolling: true,
              tabKey: 'AccountSystemUsageReport',
              url: '/aspx/Administration/AccountUsageReport.aspx',
              visible: true,
            },
            {
              tabName: 'On-Demand Usage Report',
              scrolling: true,
              tabKey: 'OnDemandUsageReport',
              url: '/aspx/Administration/OnDemandUsageReport.aspx',
              visible: true,
            },
            {
              tabName: 'Event Logs / Status',
              scrolling: true,
              tabKey: 'EventLogsStatus',
              url: '/aspx/Administration/EventLogsStatus.aspx',
              visible: true,
            },
            {
              tabName: 'Current Inactives',
              scrolling: true,
              tabKey: 'CurrentInactives',
              url: '/aspx/Administration/CurrentInactives.aspx',
              visible: true,
            },
            {
              tabName: 'Text Message Reply Report',
              scrolling: true,
              tabKey: 'TextMessageReplyReport',
              url: '/aspx/Administration/SMSMessageReply.aspx',
              visible: true,
            },
            {
              tabName: 'Alexa Usage Report',
              scrolling: true,
              tabKey: 'AlexaUsageReport',
              url: '/aspx/Administration/AlexaUsageReport.aspx',
              visible: true,
            },
            {
              tabName: 'Alexa Usage Summary Report',
              scrolling: true,
              tabKey: 'AlexaUsageSummaryReport',
              url: '/aspx/Administration/AlexaUsageSummaryReport.aspx',
              visible: true,
            },
            // TODO: should be thought how to add shouldDisplay flag here
            {
              tabName: `${_capitalize(translateUserType('staff'))} Compliance Dashboard`,
              scrolling: true,
              tabKey: 'StaffComplianceDashboard',
              url: '/aspx/Calendar/StaffComplianceDashboard.aspx',
              visible: true,
            },
            {
              tabName: 'Scheduled Reports',
              tabKey: 'ScheduledReports',
              url: '/aspx/Administration/ScheduledReport/ScheduledReports.aspx',
              visible: true,
            },
          ]}
          />
        )}
      />
      <Route
        path="/legacy/messagesettings"
        render={() => (
          <IFrameTabs params={[
            {
              tabName: 'Broadcast Setup',
              tabKey: 'BroadcastSetup',
              url: '/aspx/Administration/BroadcastMessageSetup.aspx',
              visible: true,
            },
            {
              tabName: 'OnDemand Setup',
              tabKey: 'OnDemandSetup',
              url: '/aspx/Administration/OnDemandSetup.aspx',
              visible: true,
            },
            {
              tabName: 'Appointment Reminder Setting',
              scrolling: true,
              tabKey: 'AppointmentReminder Setting',
              url: '/aspx/Administration/AppointmentReminderSetting.aspx',
              visible: true,
            },
            {
              tabName: 'Automatic Events',
              scrolling: true,
              tabKey: 'AutomaticEvents',
              url: '/aspx/Administration/EventSetUp.aspx',
              visible: true,
            },
          ]}
          />
        )}
      />
    </Switch>
  );
}

function AppRoutes() {
  const [isSuper, isDischargedPatients] = useHasRoles(['Super', 'DischargedPatients']);

  return (
    <AppWrapper>
      <Switch>
        <Route
          path="/mfa-create"
          component={CreateMfa}
        />
        <Route
          path="/administration/compliance"
          render={() => (
            <Container>
              <Compliance />
            </Container>
          )}
        />
        <Route
          path="/administration/import"
          render={() => (
            <Container>
              <Import />
            </Container>
          )}
        />
        <Route
          path="/administration/conference"
          render={() => (
            <Container>
              <Conference />
            </Container>
          )}
        />
        <Route
          path="/templates"
          render={() => (<TemplateRoutes />)}
        />
        <Route
          path="/marketing-templates"
          render={() => <MarketingTemplateRoutes />}
        />
        <Route
          path="/feedback360"
          render={() => <BroadcastFormsRoutes />}
        />
        <Route
          path="/smsinbox"
          render={() => (
            <SmsInboxPage />
          )}
        />
        <Route
          path="/broadcast-history"
          render={() => (
            <BroadcastHistoryPage />
          )}
        />
        <Route
          path="/sms-auto-reply"
          render={() => (
            <SmsAutoReplyRoutes />
          )}
        />
        <Route
          path="/dischargedpatients-dashboard"
          render={() => (
            <DashboardRoutes />
          )}
        />
        <Route
          path="/pbi"
          render={() => (
            <PbiRoutes />
          )}
        />
        <Route
          path="/v1sync"
          render={() => (
            <V1SyncRoutes />
          )}
        />
        <Route
          path="/dischargedpatients"
          render={() => (
            <DischargedPatientsRoutes />
          )}
        />
        <Route
          path="/calendarevent"
          render={() => (
            <Container>
              <CalendarEventRoutes />
            </Container>
          )}
        />
        <Route
          path="/events"
          render={() => (
            <Container>
              <EventsRoutes />
            </Container>
          )}
        />
        <Route
          path="/transactionaltemplates"
          render={() => (
            <Container>
              <TransactionalTemplateRoutes />
            </Container>
          )}
        />
        {isDischargedPatients && isSuper && (
          <Route
            path="/surveyQuestionLists"
            render={() => (
              <Container>
                <SurveyQuestionListRoutes />
              </Container>
            )}
          />
        )}
        <Route path="/calendar" component={CalendarRoutes} />
        <Route path="/legacy" component={LegacyUiRoutes} />
        <Route path="/account/:id" component={RerouteAccount} />
        <Route path="/profiles" render={() => <ProfilesRoutes />} />
        <Route
          path="/groups"
          render={() => (
            <Container>
              <GroupsRoutes />
            </Container>
          )}
        />
        <Route
          path="/librarycontent"
          render={() => (
            <Container>
              <LibraryContentRoutes />
            </Container>
          )}
        />
        <Route
          path="/discharged-patient"
          render={() => (
            <AdministrationRoutes />
          )}
        />
        <Route
          path="/enterprisereport"
          render={() => (
            <Container>
              <EnterpriseReportPage />
            </Container>
          )}
        />
        <Route
          path="/enterprisetextreport"
          render={() => (
            <Container>
              <EnterpriseTextReportPage />
            </Container>
          )}
        />
        <Route
          path="/enterprisesurveys/pbi/report"
          render={() => (
            <EnterpriseSurveysReportPage />
          )}
        />
        <Route
          path="/enterprisestaffsurveys/pbi/report"
          render={() => (
            <EnterpriseStaffSurveyReportPage />
          )}
        />
        <Route
          path="/enterpriseresidentsurveys/pbi/report"
          render={() => (
            <EnterpriseResidentSurveyReportPage />
          )}
        />
        <Route
          path="/dashboard"
          render={() => (
            <DashboardRoutes />
          )}
        />
        <Route
          path="/accushield"
          render={() => (
            <Container>
              <AccushieldReportPage />
            </Container>
          )}
        />
        <Route
          path="/reports"
          render={() => (
            <Container>
              <ReportPage />
            </Container>
          )}
        />
        {isSuper && (
          <Route
            path="/internal"
            render={() => (
              <Container>
                <InternalSettingRoutes />
              </Container>
            )}
          />
        )}
        <Route
          path="/404"
          render={() => (
            <Container>
              <Error404Page />
            </Container>
          )}
        />
        <Route
          path="/403"
          render={() => (
            <Container>
              <Error403Page />
            </Container>
          )}
        />
        <Route
          path="/500"
          render={() => (
            <Container>
              <Error500Page />
            </Container>
          )}
        />
        <Route
          path="/loggedout"
          render={() => (
            <Container>
              <LoggedOutPage />
            </Container>
          )}
        />
        <Route path="/" exact>
          <Redirect to="/calendar" />
        </Route>
        <Route
          render={() => (
            <Container>
              <Error404Page />
            </Container>
          )}
        />
      </Switch>
    </AppWrapper>
  );
}

export default AppRoutes;
