import React, { useState, useMemo, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Card, Spinner } from 'react-bootstrap';
import { useJobWithLogs } from 'hooks/V1SyncHooks';
import Checkbox from 'components/Inputs/Checkbox';
import Input from 'components/Inputs/Input';
import { V1SyncJobLogList } from './V1SyncJobLogList';
import { formatV1SyncJobStatus } from './TenantJobsTable';

const V1SyncJobInfoPage: React.FC = () => {
  const { jobId } = useParams<{ tenantId: string, jobId: string }>();
  const { data: jobWithLogs, error, mutate } = useJobWithLogs(Number(jobId));

  const [logFilters, setLogFilters] = useState({
    Info: true,
    Warning: true,
    Error: true,
  });

  const [textFilter, setTextFilter] = useState<string>();

  useEffect(() => {
    if (jobWithLogs?.Info.Status === 'InProgress') {
      const interval = setInterval(() => {
        mutate();
      }, 5000);
      return () => clearInterval(interval); // Cleanup interval on unmount
    }
    return () => {};
  }, [jobWithLogs?.Info.Status, mutate]);

  const filteredLogs = useMemo(() => {
    if (!jobWithLogs) return [];
    const filterLowercase = textFilter?.toLowerCase();
    return jobWithLogs.Logs.filter((log) => logFilters[log.Level]
      && (!filterLowercase || log.Message.toLowerCase().includes(filterLowercase)));
  }, [jobWithLogs, logFilters, textFilter]);

  const handleFilterChange = (level: string) => {
    setLogFilters((prev) => ({ ...prev, [level]: !prev[level] }));
  };

  if (error) {
    return <div className="text-danger">Failed to load job data.</div>;
  }

  return (
    <div className="container mt-4">
      {jobWithLogs && (
        <Card className="mb-4">
          <Card.Header as="h5">Job Info</Card.Header>
          <Card.Body>
            <p>
              <strong>Job ID:</strong>
              {' '}
              {jobWithLogs.Info.JobId}
            </p>
            <p>
              <strong>Tenant ID:</strong>
              {' '}
              {jobWithLogs.Info.TenantId}
            </p>
            <p>
              <strong>Status:</strong>
              {' '}
              {formatV1SyncJobStatus(jobWithLogs.Info.Status)}
            </p>
            <p>
              <strong>Job Type:</strong>
              {' '}
              {jobWithLogs.Info.JobType}
            </p>
            <p>
              <strong>Triggered By:</strong>
              {' '}
              {jobWithLogs.Info.TriggeredBy}
            </p>
            <p>
              <strong>Started At:</strong>
              {' '}
              {new Date(jobWithLogs.Info.StartedAt).toLocaleString()}
            </p>
            {jobWithLogs.Info.CompletedAt && (
              <p>
                <strong>Completed At:</strong>
                {' '}
                {new Date(jobWithLogs.Info.CompletedAt).toLocaleString()}
              </p>
            )}
          </Card.Body>
        </Card>
      )}

      <Card>
        <Card.Header as="h5" className="d-flex justify-content-between align-items-center">
          <div className="d-flex align-items-center">
            {jobWithLogs?.Info.Status === 'InProgress' && (
              <Spinner animation="border" role="status" size="sm" className="mr-2" variant="primary" />
            )}
            Logs
          </div>
          <div className="d-flex">
            {Object.keys(logFilters).map((level) => (
              <Checkbox
                key={level}
                label={level}
                name={level}
                checked={logFilters[level]}
                onChange={() => handleFilterChange(level)}
                className="ml-2"
              />
            ))}
          </div>
        </Card.Header>
        <Card.Body>
          <Input
            type="text"
            placeholder="Filter log messages"
            value={textFilter}
            onChange={(e) => setTextFilter(e.target.value)}
          />
          <V1SyncJobLogList logs={filteredLogs} />
        </Card.Body>
      </Card>

    </div>
  );
};

export default V1SyncJobInfoPage;
