import React from 'react';
import Select from 'react-select';

export interface IOption {
  value: string | number
  label: string
}

interface SearchableDropdownProps {
  options: IOption[]
  value?: IOption | null
  onChange: (selectedOption: IOption | null) => void
  placeholder?: string
  isDisabled?: boolean
  isClearable?: boolean
}

const SearchableDropdown: React.FC<SearchableDropdownProps> = ({
  options,
  value,
  onChange,
  placeholder = 'Select an option...',
  isDisabled = false,
  isClearable = true,
}) => (
  <Select
    options={options}
    value={value}
    onChange={onChange}
    placeholder={placeholder}
    isDisabled={isDisabled}
    isClearable={isClearable}
    isSearchable
  />
);

export default SearchableDropdown;
