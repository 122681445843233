import React, { useCallback } from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { FixedSizeList as List } from 'react-window';
import { V1SyncJobLogModel } from 'types/V1SyncTypes';

const getColor = (level: string) => {
  switch (level) {
    case 'Warning':
      return 'text-warning';
    case 'Error':
      return 'text-danger';
    case 'Info':
    default:
      return 'text-dark';
  }
};

const copyToClipboard = (text: string) => {
  navigator.clipboard.writeText(text).then(() => {
    toast.info('Copied to Clipboard');
  }).catch((err) => {
    toast.error('Unknown error: failed to copy to clipboard');

    console.error('Failed to copy:', err);
  });
};

export const V1SyncJobLogList = ({ logs }: { logs: V1SyncJobLogModel[] }) => {
  // eslint-disable-next-line react/no-unused-prop-types
  const Row = useCallback(({ index, style }: { index: number, style: React.CSSProperties }) => {
    const log = logs[index];
    const formattedMessage = `[${new Date(log.Timestamp).toLocaleString()}] -- ${log.Message}`;

    return (
      <OverlayTrigger
        placement="top"
        overlay={<Tooltip id={`tooltip-${index}`} className="log-entry-tooltip">{log.Message}</Tooltip>}
      >
        <div
          role="button"
          tabIndex={0}
          style={style}
          className={`list-group-item ${getColor(log.Level)}`}
          onClick={() => copyToClipboard(log.Message)}
        >
          <span className="log-entry">{formattedMessage}</span>
        </div>
      </OverlayTrigger>
    );
  }, [logs]);

  return (
    <List
      height={400} // Matches your max-height
      itemCount={logs.length}
      itemSize={48} // Adjust row height as needed
      width="100%"
    >
      {Row}
    </List>
  );
};
