import React, {
  FC,
} from 'react';
import { Col, Row } from 'react-bootstrap';
import { EnterpriseBroadcastFormGeneratorFilter } from 'types/BroadcastForm';
import { Control } from 'react-hook-form';
import { EbfGeneratorFilter } from './EbfGeneratorFilter';

type EbfGeneratorFiltersProps = {
  data: EnterpriseBroadcastFormGeneratorFilter[]
  onAddFilter: () => void
  onRemoveFilter: (Id: string) => void
  loading: boolean
  control: Control
  watch: any
  errors: any
};

export const EbfGeneratorFilters: FC<EbfGeneratorFiltersProps> = ({
  data,
  onRemoveFilter,
  onAddFilter,
  loading,
  control,
  watch,
  errors,
}) => {
  const addButtonDisabled = loading || data.length >= 5;
  return (
    <>
      <Row>
        <Col xs="12" md="12">
          <h4 className="mt-4 mb-4">Filters:</h4>
        </Col>
      </Row>
      <Row className="filter-row">
        <Col xs="12" md="12">
          {data.map((filter, index) => (
            <EbfGeneratorFilter
              filter={filter}
              index={index}
              onRemoveFilter={onRemoveFilter}
              control={control}
              watch={watch}
              errors={errors}
            />
          ))}
        </Col>
      </Row>
      <Row>
        <Col xs="12" md="12">
          <button
            type="button"
            className="btn btn-primary mt-4 mb-4"
            disabled={addButtonDisabled}
            onClick={onAddFilter}
          >
            Add
          </button>
        </Col>
      </Row>
    </>
  );
};
