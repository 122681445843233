import React, { useEffect, useMemo, useState } from 'react';
import { useTenantConfig } from 'hooks/V1SyncHooks';
import { Button, Spinner, Table } from 'react-bootstrap';
import { updateMappingEnablements } from 'api/V1SyncAPI';
import { CaremergeFacilityConfig } from 'types/V1SyncTypes';
import { toast } from 'react-toastify';
import Checkbox from 'components/Inputs/Checkbox';
import { useIsModifed } from 'hooks/useIsModified';

interface V1SyncWebhookSettingsProps {
  tenantId: number
}

const V1SyncWebhookSettings: React.FC<V1SyncWebhookSettingsProps> = ({ tenantId }) => {
  const { data: existingConfig, mutate } = useTenantConfig(tenantId);
  const [accountEnablement, setAccountEnablement] = useState<Record<number, boolean>>({});
  const [loading, setLoading] = useState<boolean>(false);

  const initialSettingsState = useMemo(() => existingConfig?.Facilities.reduce(
    (acc, facility) => ({
      ...acc,
      [facility.VFAccountId]: !!facility.VFApiKey,
    }),
    {} as Record<number, boolean>,
  ) ?? [], [existingConfig]);

  useEffect(() => {
    setAccountEnablement(initialSettingsState);
  }, [initialSettingsState]);

  const isModified = useIsModifed(initialSettingsState, accountEnablement);

  const handleToggle = (accountId: number) => {
    setAccountEnablement((prev) => ({
      ...prev,
      [accountId]: !prev[accountId],
    }));
  };

  const handleSubmit = async () => {
    setLoading(true);
    try {
      await updateMappingEnablements(tenantId, accountEnablement);
      mutate();
      toast.success('Webhook enablement updated successfully');
    } catch (error) {
      toast.error('Failed to update webhook enablement');
    }
    // delay so that button doesnt flash briefly while the request finished
    // but the new data hasnt been refetched yet
    setTimeout(() => setLoading(false), 500);
  };

  if (!existingConfig?.Facilities?.length) {
    return <p>No facility mappings available.</p>;
  }

  return (
    <div className="mt-4">
      <p>
        Control whether webhooks are enabled for each account-facility mapping. When enabled, any
        changes made on GoIcon will be automatically synced to Comms. When disabled, changes on GoIcon
        will not be synced unless explicity pulled via a background job.
        Note: Webhooks must also be enabled for the facility on the GoIcon side.
      </p>
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>Account ID</th>
            <th>Facility ID</th>
            <th>Webhook Enabled</th>
          </tr>
        </thead>
        <tbody>
          {existingConfig.Facilities.map((facility: CaremergeFacilityConfig) => (
            <tr key={facility.VFAccountId}>
              <td>{facility.VFAccountId}</td>
              <td>{facility.CMFacilityId}</td>
              <td className="text-center">
                <Checkbox
                  type="checkbox"
                  checked={accountEnablement[facility.VFAccountId] || false}
                  onChange={() => handleToggle(facility.VFAccountId)}
                  name={`${facility.VFAccountId}`}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      <div className="d-flex align-items-center mt-3">
        <Button variant="primary" onClick={handleSubmit} disabled={!isModified || loading}>
          Save Changes
        </Button>
        <Spinner
          hidden={!loading}
          as="span"
          animation="border"
          variant="primary"
          size="sm"
          className="ml-2"
        />
      </div>
    </div>
  );
};

export default V1SyncWebhookSettings;
