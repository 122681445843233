import { useSelector } from 'react-redux';
import { RootState } from 'types/rootState';
import userflow from 'userflow.js';
import { useEffect } from 'react';
import { getCurrentAccount } from 'reducers/UserInfo';
import { useIsLoginValid } from './useIsLoginValid';

export const useInitUserflow = () => {
  const { userId, email, loginUserId } = useSelector((state: RootState) => state.UserInfo);
  const currentAccount = useSelector(getCurrentAccount);
  const isLoginValid = useIsLoginValid();

  useEffect(() => {
    if (!isLoginValid || !userId || !email || !loginUserId || !currentAccount?.Id || !currentAccount?.Name) return;

    // if not on production we should also return?
    const USERFLOW_TOKEN = 'ct_lwc6hg2bknf4vogheza3s5mbpu';
    userflow.init(USERFLOW_TOKEN);

    const environmentStr = process.env.REACT_APP_ENVIRONMENT ?? 'Prod';
    userflow.identify(`${environmentStr}-${loginUserId}`, {
      email,
      environment: environmentStr,
      loginUserId: `${loginUserId}`,
      userId: `${userId}`,
      accountId: `${currentAccount.Id}`,
      accountName: `${currentAccount.Name}`,
    });
  }, [email, isLoginValid, userId, loginUserId, currentAccount?.Id, currentAccount?.Name]);
}
