import React,
{
  ReactElement, useEffect, useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  linkVoicefriend, setCurrentNavItem, authenticateWithCaremergeToken, authenticate,
} from 'reducers/UserInfo';
import { useQueryParam } from 'use-query-params';
import JwtDecode from 'jwt-decode';
import { useForm } from 'react-hook-form';
import { RootState } from 'types/rootState';
import { Alert, Form } from 'react-bootstrap';
import { Input } from 'components/FormControls';
import SubmitButton from 'components/FormControls/SubmitButton';
import { Helmet } from 'react-helmet';
import logo from '../styles/images/voicefriend-icon-logo.png';

interface ILinkVoicefriendProp {
  emailAddress: string
  password: string
}

interface ICaremergeLinkToVoicefriend {
  Email: string
  CaremergeUserId: number
}

interface IProps {
  title: string
  children: React.ReactNode
}

function decodeCaremergeJwt(cmJwt: string): ICaremergeLinkToVoicefriend {
  const jwt = JwtDecode<ICaremergeLinkToVoicefriend>(cmJwt);
  const {
    Email,
    CaremergeUserId,
  } = jwt;
  return {
    Email,
    CaremergeUserId,
  };
}

function LinkVoicefriend(): ReactElement {
  const {
    handleSubmit, errors, control, getValues,
  } = useForm<ILinkVoicefriendProp>();
  const [caremergeJwt] = useQueryParam<string>('jwt');
  const [emailAddress, setEmailAddress] = useState<string>();
  const [checkingIfAlreadyLinked, setCheckingIfAlreadyLinked] = useState<boolean>(true);

  const history = useHistory();
  const dispatch = useDispatch();
  const {
    loggedIn, loading, error,
  } = useSelector((state: RootState) => state.UserInfo);

  useEffect(() => {
    if (!caremergeJwt) {
      history.push('/login');
      return;
    }
    const obj = decodeCaremergeJwt(caremergeJwt);
    setEmailAddress(obj.Email);

    dispatch(authenticateWithCaremergeToken(caremergeJwt,
      () => {
        history.push('/calendar');
      },
      (err) => {
        if (err?.response?.status === 403) {
          history.push('/login');
        } else {
          setCheckingIfAlreadyLinked(false);
        }
      }));
  }, [caremergeJwt, dispatch, history]);

  const renderError = (): ReactElement => {
    if (error.failure) {
      return <Alert variant="danger">{error.message}</Alert>;
    }
    return null;
  };

  const renderCheckingAuthentication = () => (
    <h4>Searching for linked user account... please wait</h4>
  );

  const renderTitle = () => (
    <>
      <h4>
        {loggedIn ? 'Link your account to VoiceFriend' : 'We need to confirm your identity'}
      </h4>
      {!loggedIn && (
        <div>
          In order for us to grant access your account,
          we need to validate your identity.
          please login using your current VoiceFriend credentials or click the link below to receive a verification email
        </div>
      )}
      {loggedIn && (
        <div>
          Click the button below to link Voicefriend and GoIcon users for:
        </div>
      )}
    </>
  );

  const login = () => (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Form.Group>
        <Input
          id="emailaddress"
          name="emailaddress"
          type="email"
          label="Email"
          value={emailAddress}
          readOnly
          control={control}
        />
      </Form.Group>
      <Form.Group>
        <Input
          id="password"
          name="password"
          className={errors.password ? 'errorBorder' : ''}
          type="password"
          label="Voicefriend Password"
          control={control}
          rules={{
            required: 'Password must be supplied',
          }}
        />
        <span className="text-danger">
          {errors.password && errors.password.message}
        </span>
      </Form.Group>
      <SubmitButton
        label="Submit"
        savingLabel="Saving..."
        className="btn btn-primary"
        saveComplete={!loading}
      />
    </Form>
  );

  const linkVFAccount = () => (
    <Form onSubmit={handleSubmit(onLinkVoiceFriendSubmit)}>
      <Form.Group>
        <Input
          id="emailaddress"
          name="emailaddress"
          type="email"
          label="Email"
          value={emailAddress}
          readOnly
          control={control}
        />
      </Form.Group>
      <SubmitButton
        label="Link to VoiceFriend"
        savingLabel="Saving..."
        className="btn btn-primary"
        saveComplete={!loading}
      />
    </Form>
  );

  const onSubmit = (): void => {
    const pwd = getValues('password');
    dispatch(setCurrentNavItem(null));
    dispatch(authenticate(emailAddress, pwd));
  };

  const onLinkVoiceFriendSubmit = (): void => {
    dispatch(setCurrentNavItem(null));
    dispatch(linkVoicefriend(caremergeJwt, null, null, () => {
      history.push('/calendar');
    }, () => {
    }));
  };

  if (checkingIfAlreadyLinked) {
    return (
      <NoNavFrameLink title="Link Voicefriend">
        {renderCheckingAuthentication()}
      </NoNavFrameLink>
    );
  }

  if (!emailAddress?.trim()) {
    return (
      <NoNavFrameLink title="Link Voicefriend">
        <h4>Your user does not have an email address set. This is required for the communications product.</h4>
      </NoNavFrameLink>
    );
  }

  return (
    <NoNavFrameLink title="Link Voicefriend">
      {renderTitle()}
      {renderError()}
      {!loggedIn ? login() : linkVFAccount()}
    </NoNavFrameLink>
  );
}

export function NoNavFrameLink({ title = 'Link Voicefriend', children }: IProps): ReactElement {
  return (
    <div className="linkVoicefriend">
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <div className="linkVoicefriend-form">
        <div className="form-container">
          <img className="logo" alt="VoiceFriend logo" src={logo} />
          {children}
        </div>
        <div className="copyright label">
          &copy;
          {new Date().getFullYear()}
          {' '}
          VoiceFriend LLC. All rights reserved.
        </div>
      </div>
      <div className="link-voicefriend-splash d-none d-lg-flex">
        <div className="link-voicefriend-splash__img" />
      </div>
    </div>
  );
}

export default LinkVoicefriend;
