import React from 'react';
import { Card, Accordion, ListGroup } from 'react-bootstrap';
import { V1SyncMatchingReport, V1SyncMatchResult } from 'types/V1SyncTypes';

interface FacilityMatchingReportCardProps {
  report: V1SyncMatchingReport
}

const FacilityMatchingReportCard: React.FC<FacilityMatchingReportCardProps> = ({ report }) => {
  // Helper function to render each matching result
  const renderMatchResult = (match: V1SyncMatchResult, index: number) => (
    <ListGroup.Item key={index}>
      <div>{`Similarity: ${match.Similarity}`}</div>
      <div>
        {
          `VfProfile: ${match.VfProfile.FirstName} ${match.VfProfile.LastName} 
        | ${match.VfProfile.EmailAddress}`
        }
      </div>
      <div>{`CmUser: ${match.CmUser?.FirstName} ${match.CmUser?.LastName} | ${match.CmUser?.Email}`}</div>
      <div>{`ReportRecord: ${match.ReportRecord}`}</div>
    </ListGroup.Item>
  );

  return (
    <Card className="mb-3">
      <Card.Header>Matching Report</Card.Header>
      <Card.Body>
        <Card.Title>Summary</Card.Title>
        <ListGroup variant="flush" className="mb-3">
          <ListGroup.Item>
            {
              `Staff Matches: ${report.StaffMatches} / ${report.VfStaffTotal} VF Staff,
              (${report.CmStaffTotal} CM Staff Total)`
            }
          </ListGroup.Item>
          <ListGroup.Item>
            {
              `Resident Matches: ${report.ResidentMatches} / ${report.VfResidentTotal} VF Residents,
            (${report.CmResidentTotal} CM Resident Total)`
            }
          </ListGroup.Item>
          <ListGroup.Item>
            {
              `Family Matches: ${report.FamilyMatches} / ${report.VfFamilyTotal} VF Family,
            (${report.CmFamilyTotal} CM Family Total)`
            }
          </ListGroup.Item>
        </ListGroup>

        <Accordion defaultActiveKey="-1">
          <Card>
            <Card.Header>
              <Accordion.Toggle as="div" eventKey="0">
                View Matching Details (
                {report.Matching.length}
                {' '}
                results)
              </Accordion.Toggle>
            </Card.Header>
            <Accordion.Collapse eventKey="0">
              <Card.Body>
                {report.Matching.length > 0 ? (
                  <ListGroup variant="flush">
                    {report.Matching.map(renderMatchResult)}
                  </ListGroup>
                ) : (
                  <p>No matching results</p>
                )}
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        </Accordion>
      </Card.Body>
    </Card>
  );
};

export default FacilityMatchingReportCard;
