import {
  getAllJobsForTenant, getAllV1SyncedAccounts, getJobWithLogs, getTenantConfig, getTenantsWithInfo,
} from 'api/V1SyncAPI';
import useSWR from 'swr';
import { V1SyncJobModel, V1SyncJobWithLogs } from 'types/V1SyncTypes';

export const useTenantsWithInfo = () => useSWR(
  ['tenantIdsWithConfigs'],
  async () => {
    const response = await getTenantsWithInfo({
      ignoreGlobalCatch: true,
    });
    return response;
  },
  {
    shouldRetryOnError: false,
  },
);

export const useTenantConfig = (tenantId: number | 'new') => useSWR(
  ['tenantConfigs', tenantId],
  async () => {
    if (tenantId === 'new') return null;
    const response = await getTenantConfig(tenantId, {
      ignoreGlobalCatch: true,
    });
    return response;
  },
  {
    shouldRetryOnError: false,
  },
);

export const useJobsForTenant = (tenantId: number) => useSWR<V1SyncJobModel[]>(
  ['jobsForTenant', tenantId],
  async () => {
    if (!tenantId) return [];
    const response = await getAllJobsForTenant(tenantId, {
      ignoreGlobalCatch: true,
    });
    return response;
  },
  { shouldRetryOnError: false },
)

export const useJobWithLogs = (jobId: number, levelFilter?: string) => useSWR<V1SyncJobWithLogs>(
  ['jobLogs', jobId, levelFilter],
  async () => {
    const response = await getJobWithLogs(jobId, levelFilter, {
      ignoreGlobalCatch: true,
    });
    return response;
  },
  { shouldRetryOnError: false },
);

export const useAllV1SyncedAccounts = (includeUnsynced: boolean) => useSWR(
  ['v1syncedAccounts', includeUnsynced],
  async () => {
    const response = await getAllV1SyncedAccounts(includeUnsynced, {
      ignoreGlobalCatch: true,
    });
    return response;
  },
  {
    shouldRetryOnError: false,
  },
);
